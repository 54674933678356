import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GetSignedUrlRequest } from '../dto/images/get-signed-url-request.dto';
import { GetSignedUrlResponse } from '../dto/images/get-signed-url-response.dto';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private readonly httpHeaders: HttpHeaders;
  private readonly baseUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.baseUrl = `${environment.apiUrl}/images`;
  }

  public getSignedUrl(getSignedUrlRequest: GetSignedUrlRequest) {
    const endpoint = `${this.baseUrl}/get-signed-url`;
    return this.http.post<GetSignedUrlResponse>(endpoint, getSignedUrlRequest, {
      headers: this.httpHeaders,
    });
  }

  public getBlobImageFromUrl(url: string) {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  public uploadImage(signedUrl: string, file: any) {

    const headers = new HttpHeaders({
      'Content-Type': file.type,
      'Ignore-Authorization': 'true'
    });

    return this.http.put<any>(signedUrl, file, {
      headers: headers
    });
  }

}
