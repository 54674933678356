<section class="mt-4">
  <div class="container">
    <div class="row">
      <div *ngIf="!selectedOrder" class="col-xl-12 col-lg-2 mb-5 mb-lg-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <h2>
              <i class="fa fa-shopping-bag text-primary"></i> Órdenes de compra
            </h2>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="d-flex justify-content-end">
              <button class="btn btn-info mb-3" (click)="reloadOrders()">
                <i class="fa fa-rotate-right"></i>
              </button>
              <button class="btn btn-secondary mb-3 ms-2">
                <i class="fa fa-plus"></i> Crear orden
              </button>
            </div>
          </div>
        </div>

        <table class="table table-hover table-responsive-md table-responsive bg-white shadow">
          <thead>
            <tr>
              <th class="py-3 ps-4 text-sm border-0">
                # Orden
              </th>
              <th class="py-3 text-sm text-center border-0">
                Fecha
              </th>
              <th class="py-3 text-sm text-center border-0">
                Cliente
              </th>
              <th class="py-3 text-sm text-center border-0">
                Formulario
              </th>
              <th class="py-3 text-sm text-center border-0">
                Total
              </th>
              <th class="py-3 text-sm text-center border-0" *ngIf="false">
                Status
              </th>
              <th class="py-3 text-sm text-center border-0">
                Imágenes
              </th>
              <th class="py-3 text-sm text-center border-0">
                Formulario
              </th>
              <th class="py-3 text-sm text-center border-0">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="loadingOrders">
              <td class="ps-4" colspan="7">
                Cargando...
              </td>
            </tr>
            <tr *ngIf="!loadingOrders && orders?.length === 0">
              <td class="ps-4" colspan="5">
                Sin registros
              </td>
            </tr>
            <tr *ngFor="let order of orders">
              <th class="ps-4 py-2 align-middle">
                <a role="button" (click)="viewOrderUrl(order)" class="text-decoration-underline"
                  [ngClass]="{
                    'text-success': order.status === ProductOrderStatusType.Completed,
                    'text-warning': order.status === ProductOrderStatusType.Draft
                  }">
                  {{ order.shopifyOrder?.name || order.shopifyDraftOrder?.name }}
                </a>
              </th>
              <td class="py-2 align-middle text-center">
                {{ order.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
              </td>
              <td class="py-2 align-middle text-center">
                {{ order.customerName }}
              </td>
              <td class="py-2 align-middle text-center">
                <a *ngIf="order.formFilledAt"
                   class="btn btn-sm btn-outline-primary"
                   (click)="viewCustomerFormData(order)">
                  <i class="fa fa-magnifying-glass"></i> Formulario
                </a>
                <a *ngIf="order.shopifyOrder"
                   class="btn btn-sm btn-outline-info ms-2"
                   (click)="viewCustomerUrl(order)">
                  <i class="fa fa-external-link"></i> Cliente
                </a>
              </td>
              <td class="py-2 align-middle text-center">
                {{ order.shopifyOrder?.total_price || order.shopifyDraftOrder?.total_price | currency }}
              </td>
              <td class="py-2 align-middle text-center" *ngIf="false">
                <ng-container *ngIf="order.status === ProductOrderStatusType.Draft">
                  <span class="badge badge-danger-light" >
                    Draft
                  </span>
                  <button class="btn btn-sm btn-outline-success ms-2"
                          (click)="completeDraftOrder(order)"
                          *ngIf="order.formFilledAt">
                    <i class="fab fa-shopify"></i>
                  </button>
                </ng-container>
                <span class="badge badge-success-light" *ngIf="order.status === ProductOrderStatusType.Completed">
                  Completada
                </span>
              </td>
              <td class="py-2 align-middle text-center">
                <button
                   (click)="viewImages(order)"
                   class="btn btn-sm btn-outline-primary action-btn">
                  <i class="fa fa-magnifying-glass"></i>
                </button>
                <button
                   (click)="downloadOrderImages(order)"
                   class="btn btn-sm btn-outline-secondary action-btn ms-2">
                  <i class="fa fa-download"></i>
                </button>
              </td>
              <td class="py-2 align-middle text-center">
                <button
                  (click)="viewCustomerForm(order)"
                  class="btn btn-sm btn-outline-warning action-btn">
                  <i class="fa fa-pen-field"></i>
                </button>
                <button
                  (click)="copyOrderUrl(order)"
                  class="btn btn-sm btn-outline-warning action-btn ms-2">
                  <i class="fa fa-clipboard-question"></i>
                </button>
              </td>
              <td class="py-2 align-middle text-center">
                <ng-container *ngIf="order.shopifyDraftOrder && !order.shopifyOrder">
                  <button class="btn btn-outline-primary"
                     (click)="viewInvoiceUrl(order)">
                    Ver factura
                  </button>
                  <button *ngIf="order.shopifyDraftOrder && !order.shopifyOrder"
                     class="btn btn-outline-secondary action-btn ms-2 p-2"
                     (click)="copyInvoiceUrl(order)">
                    <i class="fa fa-clipboard"></i>
                  </button>
                </ng-container>
                <ng-container *ngIf="order.shopifyOrder">
                  <a class="btn btn-sm btn-outline-success"
                     (click)="viewOrderUrl(order)">
                    <i class="fa fa-external-link"></i> Ver orden
                  </a>
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="selectedOrder" class="col">
        <app-image-viewer
          [imageUrls]="selectedOrderImageUrls">
        </app-image-viewer>
      </div>
    </div>
  </div>
</section>

<ng-template #customerFormDataModal>
  <div class="modal-body p-0">
    <app-customer-form-data [productOrder]="selectedOrderCustomerFormData"></app-customer-form-data>
  </div>
</ng-template>

