
export function base64ToFile(base64: string, fileName: string, contentType: string): File {
  // Decodifica la cadena base64 a caracteres binarios
  const byteCharacters = atob(base64);

  // Convierte los caracteres en un array de bytes
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    // Convierte cada carácter en su representación de byte y crea un Uint8Array
    const byteArray = Uint8Array.from(slice, c => c.charCodeAt(0));
    byteArrays.push(byteArray);
  }

  // Crea un nuevo objeto Blob con los datos binarios
  const blob = new Blob(byteArrays, { type: contentType });

  // Crea y retorna un objeto File a partir del Blob
  return new File([blob], fileName, { type: contentType });
}

export function base64ToBlob(base64: string): Blob {
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}

export async function urlToFile(url: string, filename: string, fileType: string): Promise<File> {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const blob = await response.blob();
    return new File([blob], filename, { type: fileType });
  } catch (error) {
    console.error('Error fetching and processing the file:', error);
    throw error;
  }
}
