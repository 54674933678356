
<div class="container pt-4 pb-4 pb-sm-4 pb-xl-6">
  <h2 class="mb-3">
    Nuestros clientes
  </h2>
  <div class="row g-3">
    <div class="col-6 col-sm-6 col-lg-3" *ngFor="let galleryImage of galleryImages">
      <div class="d-flex justify-content-center align-items-center">
        <img
          class="img-fluid"
          [src]="galleryImage.imgSrc"
          alt="">
      </div>
    </div>
  </div>
</div>
