import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { CartProduct } from '../../../dto/shopping-cart/cart-product.dto';
import { ProductInfo } from '../../../dto/shopify/products/product-info.dto';
import { EventEmitterService, NotificationTopic } from '../../../services/ui/event-emitter.service';
import { OrdersService } from '../../../services/orders.service';
import { CreateDraftOrderRequest, LineItem } from '../../../dto/shopify/order/draft/create-draft-order-request.dto';

@Component({
  selector: 'app-shopping-cart-sidebar',
  templateUrl: './shopping-cart-sidebar.component.html',
  styleUrls: ['./shopping-cart-sidebar.component.css'],
})
export class ShoppingCartSidebarComponent implements OnInit {

  public total: number = 0;
  public cartProducts: CartProduct[] = [];
  public cartSession: string;

  public saving: boolean = false;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private shopifyOrderService: OrdersService,
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.loadCart();
    this.initializeCartEvents();
  }

  private initializeCartEvents() {
    this.eventEmitterService.getEventEmitter(NotificationTopic.CartUpdated)
      .subscribe(() => {
        this.loadCart();
      });
  }

  private loadCart(): void {
    this.cartProducts = this.shoppingCartService.getShoppingCart();
    this.total = this.shoppingCartService.getTotal();
    this.cartSession = this.shoppingCartService.getCartSession();
  }

  public incrementProduct(product: ProductInfo): void {
    this.shoppingCartService.incrementProduct(product);
    this.loadCart();
  }

  public decrementProduct(product: ProductInfo): void {
    this.shoppingCartService.decrementProduct(product);
    this.loadCart();
  }

  public removeProduct(product: ProductInfo): void {
    this.shoppingCartService.removeProduct(product);
    this.loadCart();
  }

  public goToCheckout() {

    const lineItems: LineItem[] = [];

    for (const cartProduct of this.cartProducts)
      lineItems.push(new LineItem(cartProduct.product.variants[0].id, cartProduct.quantity, cartProduct.designs));

    const createDraftOrderRequest = new CreateDraftOrderRequest(lineItems, this.cartSession);

    this.saving = true;

    this.shopifyOrderService.createDraftOrder(createDraftOrderRequest).subscribe({
      next: (response: any) => {
        window.location.href = response.invoice_url;
        console.log(response);
      },
      error: (error) => {
        console.error(error);
      },
    });

  }

}
