<div class="container video-section-container pt-2 pt-sm-2 pt-md-4 pt-lg-4 pt-xl-6 pb-4">
  <div class="row mb-sm-2 mb-lg-5">
    <div class="col-sm-8 col-md-8 col-lg-6 d-flex align-items-center">
      <div>
        <h6 class="text-uppercase text-muted letter-spacing-5 mb-1">
          Lienzarte
        </h6>
        <h2 class="mb-4">
          Tu mejor opción en decoración
        </h2>
        <p class="fs-5">
          Descubre nuestros cuadros decorativos de la más alta calidad y durabilidad,
          entregados hasta la puerta de tu hogar. Con una amplia variedad de tamaños y plantillas,
          puedes elegir el que mejor se adapte a tu estilo y espacio.
        </p>
        <p class="fs-5 text-primary">
          ¡Transforma tus paredes con arte excepcional de <strong>Lienzarte</strong>!
        </p>
      </div>
    </div>
    <div class="col-sm-4 col-md-4 col-lg-4 offset-lg-1">
      <iframe
        class="video-info"
        width="400" height="555"
        data-type="youtube"
        data-video-id="SWaZOzWMPeM?si=16811TfGp5-TQqRZ"
        src="https://www.youtube.com/embed/SWaZOzWMPeM?si=16811TfGp5-TQqRZ&autoplay=1&mute=1&cc_load_policy=0&loop=1&showinfo=0&iv_load_policy=3&rel=0&autohide=1&controls=0&fs=0&modestbranding=1&playsinline=1&&widgetid=1&enablejsapi=1"
        muted
        frameborder="0"
        title="¡Decorando con tus MEJORES recuerdos!"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen>
      </iframe>
    </div>
  </div>
</div>
