import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import Cropper from 'cropperjs';
import { base64ToFile } from '../../../../utils/file.util';
import { Painting } from '../../../../dto/product-design/product-design.dto';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  public painting: Painting;

  @Input()
  public cropImageEvent: EventEmitter<any>;

  @Input()
  public croppingImageEvent: EventEmitter<any>;

  @Input()
  public croppedImageEvent: EventEmitter<any>;

  private cropImageEventSubscription: Subscription;

  private cropper: Cropper;

  constructor(
    private readonly elementRef: ElementRef,
  ) {

  }

  ngOnInit() {

    this.cropImageEventSubscription = this.cropImageEvent.subscribe(() => {
      this.crop();
    });
  }

  ngAfterViewInit() {

    const image = this.elementRef.nativeElement.querySelector('img');

    this.cropper = new Cropper(image, {
      aspectRatio: this.painting.ar,
      autoCropArea: 1,
      viewMode: 1,
    });

  }

  ngOnDestroy() {
    this.cropper.destroy();
    this.cropImageEventSubscription.unsubscribe();
  }

  public crop() {

    this.croppingImageEvent.emit(true);

    setTimeout(() => {

      const imageUrl: string = this.cropper.getCroppedCanvas().toDataURL('images/png');

      if (imageUrl) {

        const base64: string = imageUrl.substring(imageUrl.indexOf(',') + 1);
        const file: File = base64ToFile(base64, 'image', 'images/png');

        this.croppingImageEvent.emit(false);

        const imageCroppedEvent: any = {
          file: file,
          pictureUrl: imageUrl,
        };

        this.croppedImageEvent.emit(imageCroppedEvent);
      }

    }, 100);

  }

}
