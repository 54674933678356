import { Component, Input } from '@angular/core';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';

@Component({
  selector: 'app-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.css']
})
export class ProductReviewsComponent {

  @Input()
  public product: ProductInfo;

}
