import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService, NotificationTopic } from '../../../../services/ui/event-emitter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit, OnDestroy {

  @Input()
  public product: ProductInfo;

  @Input()
  public light?: boolean;

  @ViewChild('productDesignModal')
  private productDesignModal: TemplateRef<any>;

  private readonly componentSubscription: Subscription[] = [];

  constructor(
    private readonly modalService: NgbModal,
    private readonly eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.initializeOnDesignEvents();
  }

  ngOnDestroy() {
    for (const subscription of this.componentSubscription) {
      subscription.unsubscribe();
    }
  }

  private initializeOnDesignEvents() {

    const designSubmittedSubscription = this.eventEmitterService.getEventEmitter(NotificationTopic.DesignSubmitted).subscribe(() => {
      this.modalService.dismissAll();
    });

    const designCancelledSubscription = this.eventEmitterService.getEventEmitter(NotificationTopic.DesignCancelled).subscribe(() => {
      this.modalService.dismissAll();
    });

    this.componentSubscription.push(designSubmittedSubscription);
    this.componentSubscription.push(designCancelledSubscription);
  }

  public addProductToCart() {
    this.showProductDesign();
  }

  public showProductDesign() {

    this.modalService.open(this.productDesignModal, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'modal-dialog-centered',
      backdrop: 'static',
    });

  }

}
