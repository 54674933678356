import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductCollectionComponent } from './components/product-collection/product-collection.component';
import { HomeComponent } from './components/home/home.component';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { ProductDesignComponent } from './components/product-design/product-design.component';
import {
  TermsAndConditionsComponent
} from './components/informative/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/informative/components/privacy-policy/privacy-policy.component';
import { OrdersComponent } from './components/admin/components/orders/orders.component';
import { LayoutComponent } from './layout/landing/layout.component';
import { AdminLayoutComponent } from './layout/admin/admin-layout.component';
import { CustomerFormComponent } from './components/customer/components/customer-form/customer-form.component';
import { CustomerLayoutComponent } from './layout/customer/customer-layout.component';
import { UploadSessionComponent } from "./components/customer/components/upload-session/upload-session.component";
import {
  UploadSessionManagerComponent
} from "./components/admin/components/upload-session-manager/upload-session-manager.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'terminos-y-condiciones', component: TermsAndConditionsComponent },
      { path: 'politica-de-privacidad', component: PrivacyPolicyComponent },
      { path: 'collection/:collection-name', component: ProductCollectionComponent },
      { path: 'product/:product-id/:product-name', component: ProductViewComponent },
      { path: 'product-design', component: ProductDesignComponent }
    ]
  },
  {
    path: 'lienzarte-admin',
    component: AdminLayoutComponent,
    children: [
      { path: 'orders', component: OrdersComponent },
      { path: 'uploads', component: UploadSessionManagerComponent },
      { path: '**', redirectTo: 'orders' }
    ]
  },
  {
    path: 'customer',
    component: CustomerLayoutComponent,
    children: [
      { path: 'checkout/:orderUuid', component: CustomerFormComponent },
      { path: 'upload-session/:uploadSessionUuid', component: UploadSessionComponent }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
