import { Component, OnInit } from '@angular/core';
import { FooterHyperlinks } from '../../../../dto/internal/footer/footer-hyperlinks.dto';
import { Hyperlink } from '../../../../dto/internal/common/hyperlink.dto';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public footerHyperlinksColumns: FooterHyperlinks[] = [];

  ngOnInit() {
    this.initializeFooterHyperlinks();
  }

  private initializeFooterHyperlinks() {

    this.footerHyperlinksColumns.push(
      new FooterHyperlinks('Tienda en línea', [
          new Hyperlink('Inicio', '/home'),
          new Hyperlink('Acerca de', '/about'),
          new Hyperlink('Contacto', '/contact'),
          new Hyperlink('Colecciones', '/collections'),
        ]
      ),
      new FooterHyperlinks('Redes sociales', [
          new Hyperlink('Facebook', 'https://www.facebook.com'),
          new Hyperlink('Instagram', 'https://www.instagram.com'),
          new Hyperlink('WhatsApp', 'https://www.whatsapp.com'),
        ]
      ),
      // legal
      new FooterHyperlinks('Legal', [
          new Hyperlink('Términos y condiciones', '/terminos-y-condiciones'),
          new Hyperlink('Política de privacidad', '/politica-de-privacidad'),
        ]
      ),
    );
  }

}
