import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CreateDraftOrderRequest } from '../dto/shopify/order/draft/create-draft-order-request.dto';
import { ProductOrderInfo } from '../dto/order/product-order-info.dto';
import { Observable } from 'rxjs';
import { FillCustomerFormRequest } from '../dto/order/form/fill-customer-form-request.dto';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private readonly httpHeaders: HttpHeaders;
  private readonly baseUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.baseUrl = `${environment.apiUrl}/orders`;
  }

  public createDraftOrder(createDraftOrderRequest: CreateDraftOrderRequest) {
    const endpoint = `${this.baseUrl}/draft`;
    return this.http.post<any>(endpoint, createDraftOrderRequest, {
      headers: this.httpHeaders,
    });
  }

  public getOrders(): Observable<ProductOrderInfo[]> {
    const endpoint = `${this.baseUrl}`;
    return this.http.get<ProductOrderInfo[]>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public getOrderByUuid(orderUuid: string): Observable<ProductOrderInfo> {
    const endpoint = `${this.baseUrl}/get-order/${orderUuid}`;
    return this.http.get<ProductOrderInfo>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public fillCustomerForm(fillCustomerFormRequest: FillCustomerFormRequest) {
    const endpoint = `${this.baseUrl}/fill-customer-form`;
    return this.http.post<any>(endpoint, fillCustomerFormRequest, {
      headers: this.httpHeaders,
    });
  }

  public downloadOrderImages(orderUuid: string) {
    const endpoint = `${this.baseUrl}/download-order-images/${orderUuid}`;
    return this.http.get<Blob>(endpoint, {
      headers: this.httpHeaders,
      responseType: 'blob' as 'json'
    });
  }

  public completeDraftOrder(order: ProductOrderInfo) {
    const endpoint = `${this.baseUrl}/complete-draft-order/${order.uuid}`;
    return this.http.post<any>(endpoint, order, {
      headers: this.httpHeaders,
    });
  }

}
