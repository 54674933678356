import { EventEmitter, Injectable } from '@angular/core';

export enum NotificationTopic {
  DesignSubmitted = 'design-submitted',
  DesignCancelled = 'design-cancelled',
  CartProductAdded = 'cart-product-added',
  CartUpdated = 'cart-updated',
  GoBackImageViewer = 'go-back-image-viewer',
}

function createEventEmitter(): EventEmitter<any> {
  return new EventEmitter<any>();
}

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {

  private eventEmitters: Map<NotificationTopic, EventEmitter<any>> =
    new Map<NotificationTopic, EventEmitter<any>>();

  constructor() {
    this.initializeEventEmitters();
  }

  private initializeEventEmitters() {
    for (const topic of Object.values(NotificationTopic))
      this.eventEmitters.set(topic, createEventEmitter());
  }

  public emit(topic: NotificationTopic, data?: any) {
    this.getEventEmitter(topic).next(data);
  }

  public getEventEmitter(topic: NotificationTopic): EventEmitter<any> {
    return this.eventEmitters.get(topic);
  }

}
