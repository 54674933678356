<section class="mt-0 mt-sm-0 mt-lg-5">
  <div class="container product-view-container px-lg-4 px-sm-3 px-3">
    <ng-container *ngIf="false">
      <ng-container *ngTemplateOutlet="productAddedAlertTemplate"></ng-container>
    </ng-container>
    <div class="row">
      <div class="col-lg-7 ">
        <app-product-slider [product]="product"></app-product-slider>
      </div>
      <div class="col-lg-5 ps-lg-4 order-1 order-lg-2">
        <app-product-data [product]="product"></app-product-data>
      </div>
    </div>
  </div>
</section>

<section class="mt-5" *ngIf="false">
  <div class="container px-lg-4 px-sm-3 px-3">
    <ul class="nav nav-tabs flex-column flex-sm-row" role="tablist">
      <li class="nav-item">
        <a class="nav-link detail-nav-link active" data-bs-toggle="tab" href="#description" role="tab">
          Description
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link detail-nav-link" data-bs-toggle="tab" href="#reviews" role="tab">
          Reseñas
        </a>
      </li>
    </ul>
    <div class="tab-content py-4">
      <div class="tab-pane fade show active px-3" id="description" role="tabpanel">
        <app-product-description [product]="product"></app-product-description>
      </div>
      <div class="tab-pane fade" id="reviews" role="tabpanel">
        <app-product-reviews [product]="product"></app-product-reviews>
      </div>
    </div>
  </div>
</section>

<app-related-products [collectionType]="relatedProductCollectionType"></app-related-products>

<ng-template #productAddedAlertTemplate>
  <div class="d-block" id="addToCartAlert">
    <div
      class="mb-4 mb-lg-5 alert alert-success alert-dismissible fade show"
      role="alert">
      <div class="d-flex align-items-center">
        <svg
          class="svg-icon d-none d-sm-block w-3rem h-3rem svg-icon-light flex-shrink-0 me-3">
          <use xlink:href="#checked-circle-1"></use>
        </svg>
        <p class="mb-0">
          Push-up jeans have been added to your cart.
          <br class="d-inline-block d-lg-none" />
          <a
            href="#"
            class="text-reset text-decoration-underline ms-lg-3">
            View Cart
          </a>
        </p>
      </div>
      <button
        class="btn-close btn-close-white opacity-10"
        type="button"
        data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
</ng-template>
