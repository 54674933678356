<header class="header">
  <!-- Top Bar-->
  <div class="top-bar text-sm bg-lienzarte text-white">
    <div class="container py-3">
      <div class="row align-items-center">
        <div class="col-md-4 d-none d-md-block">
          <ul class="list-inline mb-0">
            <li class="list-inline-item me-2">
              <a class="text-reset text-primary-hover" [href]="facebookUrl" target="_blank">
                <i class="fab fa-facebook-f social-network-icon"></i>
              </a>
            </li>
            <li class="list-inline-item me-2">
              <a class="text-reset text-primary-hover" [href]="whatsAppUrl" target="_blank">
                <i class="fab fa-whatsapp social-network-icon"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="text-reset text-primary-hover" [href]="instagramUrl" target="_blank">
                <i class="fab fa-instagram social-network-icon"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-center text-sm-center text-md-center">
          <p class="mb-0">
            ¡Envíos gratis a todo México!
          </p>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-xl navbar-dark bg-lienzarte px-lg-5 py-3 shadow mb-4">
    <div class="container">
      <a class="navbar-brand" href="../../../../../index.html">
        <img class="navbar-brand-img" src="./assets/img/logo/lienzarte-logo-white-letters.png" alt="lienzarte-logo-white">
      </a>
      <div class="d-flex">
        <ul class="list-inline mb-0 d-block d-lg-none">
          <li class="list-inline-item me-3">
            <a class="text-light text-primary-hover position-relative" href="wishlist.html">
              <svg class="svg-icon navbar-icon">
                <use xlink:href="#heart-1"></use>
              </svg>
              <div class="navbar-icon-badge" *ngIf="totalFavorites > 0">
                {{ totalFavorites }}
              </div>
            </a>
          </li>
          <li class="list-inline-item position-relative me-3">
            <a class="text-light text-primary-hover" href="#" data-bs-toggle="modal" data-bs-target="#sidebarCart">
              <svg class="svg-icon navbar-icon">
                <use xlink:href="#retail-bag-1"></use>
              </svg>
              <div class="navbar-icon-badge" *ngIf="totalCartProducts > 0">
                {{ totalCartProducts }}
              </div>
            </a>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <svg class="svg-icon navbar-icon">
            <use xlink:href="#menu-hamburger-1"></use>
          </svg>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav navbar-nav-separated mt-3 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']">
              Inicio
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/collection', largePaintings.name]">
              Cuadros grandes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/collection', setsOfPaintings.name]">
              Mural de cuadros
            </a>
          </li>
          <li class="nav-item" *ngIf="false">
            <a class="nav-link" [routerLink]="['/collection', collagesPaintings.name]">
              Collages
            </a>
          </li>
          <li class="nav-item" *ngIf="false">
            <a class="nav-link" [routerLink]="['/collection', collectionsPaintings.name]">
              Nuestras colecciones
            </a>
          </li>
        </ul>
        <ul class="list-inline ms-auto mb-0 d-none d-lg-block">
          <li class="list-inline-item me-3">
            <a class="text-light text-primary-hover position-relative" href="wishlist.html">
              <svg class="svg-icon navbar-icon">
                <use xlink:href="#heart-1"></use>
              </svg>
              <div class="navbar-icon-badge" *ngIf="totalFavorites > 0">
                {{ totalFavorites }}
              </div>
            </a>
          </li>
          <li class="list-inline-item position-relative me-3">
            <a class="text-light text-primary-hover" href="javascript:void(0);" (click)="openShoppingCart()">
              <svg class="svg-icon navbar-icon">
                <use xlink:href="#retail-bag-1"></use>
              </svg>
              <div class="navbar-icon-badge" *ngIf="totalCartProducts > 0">
                {{ totalCartProducts }}
              </div>
            </a>
          </li>
          <li class="list-inline-item d-block d-lg-none">
            <a class="text-light text-primary-hover" href="#" data-bs-toggle="modal" data-bs-target="#sidebarRight">
              <svg class="svg-icon navbar-icon">
                <use xlink:href="#menu-hamburger-1"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<div class="modal fade modal-right" id="sidebarCart" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content sidebar-cart-content">
      <app-shopping-cart-sidebar></app-shopping-cart-sidebar>
    </div>
  </div>
</div>
