import { Component } from '@angular/core';
import { getWhatsAppUrl } from '../../../../constants/social-networks.constants';

@Component({
  selector: 'app-whatsapp-bubble',
  templateUrl: './whatsapp-bubble.component.html',
  styleUrls: ['./whatsapp-bubble.component.css']
})
export class WhatsAppBubbleComponent {

  public whatsAppUrl: string = getWhatsAppUrl();

}
