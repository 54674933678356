
export class UserDataConversionRequest {

  firstName: string;
  lastName: string
  email: string;
  phoneNumber: string;

  city: string;
  state: string;
  postalCode: string;
  country: string;
  
  clientIpAddress: string;
  clientUserAgent: string;
  externalId: string;
  ctwaClid: string;
  fbp: string;

}