
<div class="container pt-4 pb-6 px-6">
  <div class="card">
    <div class="card-body p-5">
      <h4 class="letter-spacing-1 mb-3 text-title">
        Aviso de privacidad
      </h4>
      <p class="text-content mb-5">
        En Lienzarte, estamos comprometidos con la protección de la privacidad de nuestros clientes.
        <br>Manejamos y protegemos los archivos y fotografías proporcionados por nuestros clientes durante el proceso de compra hasta la entrega de sus productos.
      </p>
      <h6 class="text-subtitle mb-3">
        Recopilación de archivos y fotografías
      </h6>
      <p class="text-content mb-4">
        Recopilamos archivos y fotografías proporcionados por los clientes con el único propósito de personalizar los productos solicitados, como cuadros decorativos. Los archivos y fotografías pueden ser cargados directamente por el cliente a través de nuestro sitio web o enviados por WhatsApp.
      </p>
      <h6 class="text-subtitle mb-3">
        Uso de archivos y fotografías
      </h6>
      <p class="text-content mb-4">
        Los archivos y fotografías proporcionados por los clientes se utilizan exclusivamente para la creación y personalización de los productos solicitados. No compartimos, vendemos ni divulgamos de ninguna manera los archivos o fotografías de los clientes a terceros, excepto cuando sea necesario para completar la entrega del producto solicitado.
      </p>
      <h6 class="text-subtitle mb-3">
        Seguridad de los archivos y fotografías
      </h6>
      <p class="text-content mb-4">
        Implementamos medidas de seguridad físicas, electrónicas y procedimentales para proteger los archivos y fotografías de nuestros clientes contra accesos no autorizados, pérdidas, uso indebido o alteración. Nuestro equipo interno sólo tiene acceso a los archivos y fotografías necesarios para completar el proceso de personalización y entrega del producto solicitado.
      </p>
      <h6 class="text-subtitle mb-3">
        Retención de archivos y fotografías
      </h6>
      <p class="text-content mb-4">
        Conservamos los archivos y fotografías proporcionados por los clientes únicamente durante el tiempo necesario para completar el proceso de producción y entrega del producto solicitado. Después de completar el pedido, los archivos y fotografías pueden ser eliminados de nuestros sistemas, a menos que el cliente solicite lo contrario para futuros pedidos o consultas.
      </p>
      <h6 class="text-subtitle mb-3">
        Derechos de los clientes
      </h6>
      <p class="text-content mb-4">
        Los clientes tienen derecho a solicitar acceso, corrección, eliminación o limitación del procesamiento de sus archivos y fotografías personales. Para ejercer estos derechos o para realizar cualquier consulta relacionada con nuestra política de privacidad, los clientes pueden ponerse en contacto con nuestro equipo de atención al cliente.
      </p>
      <p class="text-content mb-4">
        Al utilizar nuestros servicios, los clientes aceptan los términos y condiciones descritos en esta política de privacidad. Nos reservamos el derecho de actualizar o modificar esta política en cualquier momento, y cualquier cambio se reflejará en nuestra página web.
      </p>
      <p class="text-content mb-4">
        Esta información puede ser utilizada por <em>Lienzarte</em> para propósitos legítimos asociados con las operaciones comerciales de nuestra compañía.
      </p>
      <p class="text-content mb-4">
        Si desea acceder a los datos personales que tenemos sobre usted, actualizarlos, corregirlos o ejercer el derecho de supresión total o parcial de su información de acuerdo con la ley y sin perjuicio de lo que establezcan otras disposiciones legales, por favor comuníquese con nosotros a través de cualquiera de las siguientes opciones:
      </p>
      <ul class="text-content mb-4">
        <li>Envío de un mensaje o correo postal a la dirección previamente mencionada.</li>
        <li>Correo electrónico a: <strong>contacto@lienzarte.com</strong></li>
        <li>Llamada telefónica al número: <strong>+52 33 30 24 1128</strong></li>
      </ul>
      <p class="text-content mb-4">
        Al hacer su solicitud, le pedimos que proporcione su nombre completo y dirección. Sus datos personales y el historial como cliente serán almacenados en nuestra base de datos, así como cualquier otra información personal relacionada con los productos o servicios que le hayamos proporcionado anteriormente. <em>Lienzarte</em> puede utilizar esta información para ofrecerle nuestros productos o servicios tanto de manera física, electrónica, a través de Internet, como por teléfono.
      </p>
      <p>
        <strong>Última actualización: 03/05/2024.</strong>
      </p>
    </div>
  </div>
</div>
