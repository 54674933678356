
<div *ngIf="!selectedPainting?.pictureUrlToCrop">
  <div class="text-center text-secondary mt-3">
    <h4>Seleccione sus imágenes</h4>
  </div>

<!--  <ng-container *ngFor="let design of designs">-->
<!--    <h1>{{ design.name }}</h1>-->
    <div [ngClass]="design?.className" id="design_container">
      <div
        class="image-container"
        (click)="openImagePicker(painting)"
        [ngStyle]="painting.styles"
        *ngFor="let painting of design?.paintings">
        <img
          [src]="painting.pictureUrl || getPlaceHolderImage(painting)"
          class="inner-image">
        <p class="image-container-text">
          Examinar
        </p>
      </div>
    </div>
<!--  </ng-container>-->

  <div class="d-flex justify-content-center mb-3">
    <button
      (click)="cancelChoosingImages()"
      [disabled]="submittingImages"
      class="btn btn-secondary mt-4">
      Cancelar
    </button>
    <button
      [disabled]="!validImages()"
      (click)="submitImages()"
      class="btn btn-primary ms-2 mt-4">
      {{ submittingImages ? 'Cargando...' : 'Cargar' }}
    </button>
  </div>

  <input
    [hidden]="true"
    class="form-control"
    id="imagePicker"
    type="file"
    (change)="onImagePickerSelected($event)"
  />

</div>

<div *ngIf="selectedPainting?.pictureUrlToCrop">
  <div class="card">
    <div class="card-body">
      <h3 class="card-title mb-0">
        Recorte de imagen
      </h3>
      <p>Recorte la imagen a su gusto siguiendo la relación de aspecto del cuadro</p>
      <app-image-cropper
        [cropImageEvent]="cropImageEvent"
        [croppingImageEvent]="croppingImageEvent"
        [croppedImageEvent]="croppedImageEvent"
        [painting]="selectedPainting">
      </app-image-cropper>
      <div class="modal-footer justify-content-end border-0">
        <button class="btn btn-outline-dark"
                type="button"
                data-bs-dismiss="modal"
          (click)="cancelCropImage()">
          Cancelar
        </button>
        <button class="btn btn-primary" type="button"
                (click)="cropImage()">
          {{ croppingImage ? 'Recortando...' : 'Recortar' }}
        </button>
      </div>
    </div>
  </div>
</div>
