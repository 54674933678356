<div class="modal-header border-0">
  <h5 class="modal-title mb-0">
    Tu carrito
  </h5>
  <button
    class="btn-close btn-close-lg btn-close-rotate opacity-8"
    type="button"
    data-bs-dismiss="modal">
  </button>
</div>
<div class="modal-body px-4 sidebar-cart-body">
  <div class="text-center mb-5" *ngIf="cartProducts?.length === 0">
    <svg class="svg-icon w-3rem h-3rem svg-icon-light mb-4 text-muted">
      <use xlink:href="#retail-bag-1"></use>
    </svg>
    <p>
      Tu carrito está vacío
    </p>
  </div>
  <!-- Empty cart snippet end-->
  <div class="sidebar-cart-product-wrapper custom-scrollbar" *ngIf="cartProducts?.length > 0">
    <div class="navbar-cart-product" *ngFor="let productCart of cartProducts">
      <div class="d-flex align-items-center">
        <a href="#">
          <img
            class="img-fluid navbar-cart-product-image"
            [src]="productCart.product.image?.src"
            alt="..."
          />
        </a>
        <div class="w-100">
          <a class="navbar-cart-product-remove"
             role="button"
             (click)="removeProduct(productCart.product)">
            <svg class="svg-icon sidebar-cart-icon">
              <use xlink:href="#close-1"></use>
            </svg>
          </a>
          <div class="ps-3">
            <a class="navbar-cart-product-link text-dark link-animated" href="#">
              {{ productCart.product.title }}
            </a>
            <small class="d-block text-muted">
              Cantidad: {{ productCart.quantity }}
            </small>
            <small class="d-block text-primary">
              {{ productCart.designs.length > 0 ? 'Imagenes cargadas' : 'Sin imagenes' }}
            </small>

<!--            <div class="input-group-number mb-2">-->
<!--              <button type="button" class="btn btn-outline-secondary"-->
<!--                      [disabled]="productCart.quantity === 1"-->
<!--                      (click)="decrementProduct(productCart.product)">-->
<!--                <i class="fas fa-minus minus-plus-icons"></i>-->
<!--              </button>-->
<!--              <input-->
<!--                type="number"-->
<!--                [value]="productCart.quantity"-->
<!--                min="1"-->
<!--                class="form-control detail-quantity"-->
<!--                disabled>-->
<!--              <button type="button" class="btn btn-outline-secondary"-->
<!--                      (click)="incrementProduct(productCart.product)">-->
<!--                <i class="fas fa-plus minus-plus-icons"></i>-->
<!--              </button>-->
<!--            </div>-->
            <strong class="d-block text-sm">
              <ng-container *ngIf="productCart.product?.variants[0] as variant">
                {{ variant.price | currency }}
              </ng-container>
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer sidebar-cart-footer shadow">
  <div class="w-100">
    <h5 class="mb-4" [class.text-muted]="cartProducts?.length === 0">
      Total: <span class="float-right">{{ total | currency }}</span>
    </h5>
    <div class="d-grid gap-3">
      <button
        class="btn btn-dark d-flex align-items-center justify-content-center"
        [disabled]="cartProducts?.length === 0 || saving"
        (click)="goToCheckout()">
        <span class="spinner-border spinner-border-sm me-2" role="status" *ngIf="saving"></span>
        <span>
          Finalizar pedido
        </span>
      </button>
    </div>
  </div>
</div>
