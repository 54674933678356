<div class="row">
  <div class="col-md-7">
    <h5>About</h5>
    <p class="text-muted">
      Samsa was a travelling salesman - and above it there hung a picture that
      he had recently cut out of an illustrated magazine and housed in a nice,
      gilded frame.
    </p>
    <p class="text-muted">
      He must have tried it a hundred times, shut his eyes so that he wouldn't
      have to look at the floundering legs, and only stopped when he began to
      feel a mild, dull pain there that he had never felt before.
    </p>
    <h5>You will love</h5>
    <ul class="text-muted">
      <li>He must have tried it a hundred times</li>
      <li>shut his eyes so that he wouldn't have to look</li>
      <li>at the floundering legs, and only stopped</li>
    </ul>
  </div>
  <div class="col-md-5">
    <img class="img-fluid" src="./assets/lib/img/product/detail-3.jpg" alt="" />
  </div>
</div>
