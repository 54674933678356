
<div class="container py-3 min-vh-100"
     [class.transparent]="transparent"
     [class.not-transparent]="!transparent">
  <div class="products-grid">
    <div class="hero-content pb-1">
      <h2>{{ collection?.title }}</h2>
    </div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['/']">
          Inicio
        </a>
      </li>
      <li class="breadcrumb-item active">
        {{ collection?.title | titlecase }}
      </li>
    </ol>
    <div class="row px-2">
      <div class="col-xl-3 col-lg-3 col-6"
           *ngFor="let product of products">
        <app-product-card [light]="false" [product]="product"></app-product-card>
      </div>
    </div>
  </div>
</div>
