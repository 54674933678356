import { AfterViewInit, Component, OnInit } from '@angular/core';

import AOS from "aos";

declare var initializeSliders: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public transparent: boolean = true;

  constructor(
  ) {

  }

  ngOnInit() {
    AOS.init({
      once: true
    });
  }

  ngAfterViewInit() {
    initializeSliders();
  }

}
