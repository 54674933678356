import { ProductDesign } from '../../../product-design/product-design.dto';

export class DraftOrder {

  line_items: LineItem[];

  constructor(line_items: LineItem[]) {
    this.line_items = line_items;
  }

}

export class LineItem {

  variant_id: number;
  quantity: number;
  designs: ProductDesign[] = [];

  constructor(variant_id: number, quantity: number, designs: ProductDesign[]) {
    this.variant_id = variant_id;
    this.quantity = quantity;
    this.designs = designs;
  }

}

export class CreateDraftOrderRequest {

  draft_order: DraftOrder;
  cart_session: string;

  constructor(line_items: LineItem[], cart_session: string) {
    this.draft_order = new DraftOrder(line_items);
    this.cart_session = cart_session;
  }

}
