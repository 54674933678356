
<section class="py-5 bg-lienzarte text-light">
  <div class="container">
    <h5 class="mb-4">
      Selección de la semana
    </h5>
    <h3>Tendencias</h3>
    <div class="position-relative">
      <div class="swiper swiper-container similar-slider">
        <div class="swiper-wrapper pb-2 px-2">
          <div class="swiper-slide" *ngFor="let product of products">
            <app-product-card [light]="true" [product]="product"></app-product-card>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="align-with-products">
        <div class="swiper-button-prev" id="similarPrev"></div>
        <div class="swiper-button-next" id="similarNext"></div>
      </div>
    </div>
  </div>
</section>
