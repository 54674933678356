import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SaveUploadSessionRequest } from '../dto/upload-session/save-upload-session-request.dto';
import { UploadSessionInfo } from "../dto/upload-session/upload-session-info.dto";
import { UploadSessionImageInfo } from "../dto/upload-session/upload-session-image-info.dto";

@Injectable({
  providedIn: 'root'
})
export class UploadSessionService {

  private readonly httpHeaders: HttpHeaders;
  private readonly baseUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.baseUrl = `${environment.apiUrl}/upload-sessions`;
  }

  public getUploadSession(uuid: string) {
    const endpoint = `${this.baseUrl}/get-upload-session/${uuid}`;
    return this.http.get<UploadSessionInfo>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public getUploadSessionImages(id: number) {
    const endpoint = `${this.baseUrl}/get-upload-session-images/${id}`;
    return this.http.get<UploadSessionImageInfo[]>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public getUploadSessions() {
    const endpoint = `${this.baseUrl}/get-upload-sessions`;
    return this.http.get<UploadSessionInfo[]>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public saveUploadSession(saveUploadSessionRequest: SaveUploadSessionRequest) {
    const endpoint = `${this.baseUrl}/save-session`;
    return this.http.post<UploadSessionInfo>(endpoint, saveUploadSessionRequest, {
      headers: this.httpHeaders,
    });
  }

  public deleteUploadSession(uploadSessionId: number) {
    const endpoint = `${this.baseUrl}/delete-upload-session/${uploadSessionId}`;
    return this.http.delete(endpoint, {
      headers: this.httpHeaders,
    });
  }

}
