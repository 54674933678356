import { Injectable } from '@angular/core';
import { EventEmitterService } from './ui/event-emitter.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegisterMetaPixelEventRequest } from '../dto/meta/register-meta-pixel-event-request.dto';

declare var fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class MetaConversionsService {

  private readonly httpHeaders: HttpHeaders;
  private readonly baseUrl: string;

  private customerSession: string;
  private pixelId: string = environment.pixelId;

  private readonly CUSTOMER_SESSION_KEY: string = 'customer_session';

  constructor(
    private readonly eventEmitterService: EventEmitterService,
    private readonly http: HttpClient
  ) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.baseUrl = `${environment.apiUrl}/meta-conversions`;

    this.loadSessionCart();
  }

  public initPixel() {
    fbq('init', this.pixelId, { external_id: this.customerSession });
  }

  public trackPixelEvent(eventName: string, eventId?: string, customData?: any) {
    if (eventId && customData) {
      fbq('track', eventName, customData, { eventID: eventId });
    } else if (eventId) {
      fbq('track', eventName, { eventID: eventId });
    } else {
      fbq('track', eventName);
    }
  }

  public trackConversionsApiEvent(registerEventRequest: RegisterMetaPixelEventRequest) {
    const endpoint: string = `${this.baseUrl}/register-event`;
    return this.http.post<any>(endpoint, registerEventRequest);
  }

  public getCustomerSession(): string {
    return this.customerSession;
  }

  private loadSessionCart(): void {
    const storedCustomerSession = localStorage.getItem(this.CUSTOMER_SESSION_KEY);
    if (storedCustomerSession) {
      this.customerSession = storedCustomerSession;
    } else {
      this.customerSession = this.generateSession();
    }
    this.saveCustomerSession();
  }

  private generateSession(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  private saveCustomerSession() {
    localStorage.setItem(this.CUSTOMER_SESSION_KEY, this.customerSession);
  }

  public getPublicIp(): Promise<string> {

    return new Promise((resolve, reject) => {

      fetch('https://api.ipify.org?format=json').then((response: any) => {
        return response.json();
      })
        .then((data: any) => {
          console.log('data:', data);
          resolve(data.ip);
        })
        .catch((error) => {
          console.error('Error fetching public IP:', error);
          resolve(null);
        });

    });
  }

}
