import { Component, OnInit } from '@angular/core';

import {
  collagesPaintings,
  CollectionRecord,
  collectionsPaintings,
  largePaintings,
  setsOfPaintings,
} from '../../../../constants/collections.constants';

import { facebookUrl, instagramUrl, whatsAppUrl } from '../../../../constants/social-networks.constants';
import { EventEmitterService, NotificationTopic } from '../../../../services/ui/event-emitter.service';
import { ShoppingCartService } from '../../../../services/shopping-cart.service';

declare var $: any;

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  public facebookUrl: string = facebookUrl;
  public whatsAppUrl: string = whatsAppUrl;
  public instagramUrl: string = instagramUrl;

  public largePaintings: CollectionRecord = largePaintings;
  public setsOfPaintings: CollectionRecord = setsOfPaintings;
  public collagesPaintings: CollectionRecord = collagesPaintings;
  public collectionsPaintings: CollectionRecord = collectionsPaintings;

  public totalCartProducts: number = 0;
  public totalFavorites: number = 0;

  constructor(
    private eventEmitterService: EventEmitterService,
    private shoppingCartService: ShoppingCartService,
  ) {

  }

  ngOnInit(): void {
    this.fillTotalCartProducts();
    this.initializeCartUpdatedEvent();
  }

  private initializeCartUpdatedEvent(): void {
    this.eventEmitterService.getEventEmitter(NotificationTopic.CartUpdated)
      .subscribe((data: any) => {
        this.fillTotalCartProducts();
      });

    this.eventEmitterService.getEventEmitter(NotificationTopic.CartProductAdded)
      .subscribe((data: any) => {
        this.openShoppingCart();
      });
  }

  private fillTotalCartProducts(): void {
    this.totalCartProducts = this.shoppingCartService.getTotalCartProducts();
  }

  public openShoppingCart() {
    $('#sidebarCart').modal('show');
  }

}
