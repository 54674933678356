import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GetCollectionsRequest } from '../dto/shopify/collections/get-collections-request.dto';
import { CollectionInfo } from '../dto/shopify/collections/collection-info.dto';
import { ProductInfo } from '../dto/shopify/products/product-info.dto';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private readonly httpHeaders: HttpHeaders;
  private readonly baseUrl: string;

  constructor(protected http: HttpClient) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.baseUrl = `${environment.apiUrl}/products`;
  }

  public getCollection(collectionId: number): Observable<any> {
    const endpoint = `${this.baseUrl}/collections/${collectionId}`;
    return this.http.get<any>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public getCollections(getCollectionsRequest: GetCollectionsRequest): Observable<CollectionInfo[]> {
    const endpoint = `${this.baseUrl}/collections`;
    return this.http.post<CollectionInfo[]>(endpoint, getCollectionsRequest, {
      headers: this.httpHeaders,
    });
  }

  public getCollectionProducts(collectionId: number): Observable<ProductInfo[]> {
    const endpoint = `${this.baseUrl}/collections/${collectionId}/products`;
    return this.http.get<ProductInfo[]>(endpoint, {
      headers: this.httpHeaders,
    });
  }

  public getProduct(productId: number): Observable<ProductInfo> {
    const endpoint = `${this.baseUrl}/products/${productId}`;
    return this.http.get<ProductInfo>(endpoint, {
      headers: this.httpHeaders,
    });
  }

}
