import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  setsOfPaintings,
  largePaintings,
  collagesPaintings,
  collectionsPaintings,
  CollectionType,
} from '../../../../constants/collections.constants';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';
import { ProductsService } from '../../../../services/products.service';

declare var initializeSliders: any;

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.css']
})
export class RelatedProductsComponent implements OnInit, AfterViewInit {

  @Input()
  public collectionType: CollectionType;

  public products: ProductInfo[] = [];

  constructor(
    private readonly shopifyProductsService: ProductsService,
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      try {
        initializeSliders();
      } catch (error) {  }
    }, 500);
  }

  ngOnInit() {
    if (!this.collectionType) return;
    switch (this.collectionType) {
      case CollectionType.SetsOfPaintings:
        this.fillProducts(setsOfPaintings.id);
        break;
      case CollectionType.LargePaintings:
        this.fillProducts(largePaintings.id);
        break;
      case CollectionType.CollagesPaintings:
        this.fillProducts(collagesPaintings.id);
        break;
      case CollectionType.CollectionsPaintings:
        this.fillProducts(collectionsPaintings.id);
        break;
      default:
        this.fillProducts(setsOfPaintings.id);
        break;
    }
  }

  private fillProducts(collectionId: number) {

    this.shopifyProductsService.getCollectionProducts(collectionId).subscribe({
      next: (products: ProductInfo[]) => {
        this.products = products;
        setTimeout(() => {
          try {
            initializeSliders();
          } catch (error) {  }
        }, 500);
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }


}
