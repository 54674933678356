import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../../services/products.service';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';

@Component({
  selector: 'app-trends-cards',
  templateUrl: './trends-cards.component.html',
  styleUrls: ['./trends-cards.component.css']
})
export class TrendsCardsComponent implements OnInit {

  public products: ProductInfo[] = [];

  constructor(
    private readonly shopifyProductsService: ProductsService,
  ) {

  }

  ngOnInit() {
    this.fillTrendsCollectionProducts();
  }

  private fillTrendsCollectionProducts() {

    // Trends collection id
    const trendsCollectionId: number = 308726792366;

    this.shopifyProductsService.getCollectionProducts(trendsCollectionId).subscribe({
      next: (products: ProductInfo[]) => {
        this.products = products;
      },
      error: error => {
        console.error('Error:', error);
      },
    });

  }

}
