
export class GetCollectionsRequest {

  collectionIds: number[];

  constructor(collectionIds: number[]) {
    this.collectionIds = collectionIds;
  }

}
