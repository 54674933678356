<section class="mt-4">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-2 mb-5 mb-lg-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <h2>
              <i class="fa fa-image-landscape text-primary"></i> Imágenes subidas
            </h2>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <div class="d-flex justify-content-end">
              <button class="btn btn-info mb-3" (click)="reloadUploadSessions()">
                <i class="fa fa-rotate-right"></i>
              </button>
              <button class="btn btn-primary mb-3 ms-2" (click)="showNewUploadSessionModal()">
                <i class="fa fa-plus"></i> Nueva sesión
              </button>
            </div>
          </div>
        </div>
        <table class="table table-hover table-responsive-md table-responsive bg-white shadow">
          <thead>
          <tr>
            <th class="py-3 ps-4 text-sm border-0">
              Identificador
            </th>
            <th class="py-3 text-sm text-center border-0">
              Fecha
            </th>
            <th class="py-3 text-sm text-center border-0">
              Status
            </th>
            <th class="py-3 text-sm text-center border-0">
              Ver
            </th>
            <th class="py-3 text-sm text-center border-0">
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="loadingUploadSessions">
            <td class="ps-4" colspan="5">
              Cargando...
            </td>
          </tr>
          <tr *ngIf="!loadingUploadSessions && uploadSessions?.length === 0">
            <td class="ps-4" colspan="5">
              Sin registros
            </td>
          </tr>
          <tr *ngFor="let uploadSession of uploadSessions">
            <td class="py-2 ps-4 align-middle">
              {{ uploadSession.identifier }}
            </td>
            <td class="py-2 align-middle text-center">
              {{ uploadSession.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
            </td>
            <td class="py-2 align-middle text-center">
              <button
                *ngIf="uploadSession.status === UploadSessionStatusType.Completed"
                class="btn btn-outline-success"
                (click)="viewUploadSession(uploadSession)">
                Ver imágenes
              </button>
              <span
                class="text-primary"
                *ngIf="uploadSession.status === UploadSessionStatusType.Created">
                Sesión creada
              </span>
              <span
                class="text-info"
                *ngIf="uploadSession.status === UploadSessionStatusType.Seen">
                Sesión visitada
              </span>
            </td>
            <td class="py-2 align-middle text-center">
              <button
                (click)="goToUploadSession(uploadSession)"
                class="btn btn-outline-primary action-btn">
                <i class="fa fa-external-link"></i>
              </button>
            </td>
            <td class="py-2 align-middle text-center">
              <button
                (click)="copyUploadSessionUrl(uploadSession)"
                class="btn btn-outline-warning action-btn">
                <i class="fa fa-clipboard"></i>
              </button>
              <button
                (click)="deleteUploadSession(uploadSession)"
                class="btn btn-outline-danger action-btn ms-1">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<ng-template #newUploadSessionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Crea una nueva sesión
    </h4>
    <button
      type="button"
      class="btn-close pe-4"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <div class="row g-3">
      <div class="col-12">
        <input
          type="text"
          [formControl]="uploadSessionIdentifierControl"
          minlength="6" maxlength="20"
          class="form-control"
          id="identifier"
          placeholder="Ingrese un identificador para la sesión"
          (keydown.enter)="saveUploadSession()"
          [ngClass]="{
            'is-invalid': uploadSessionIdentifierControl.touched && uploadSessionIdentifierControl.invalid,
            'is-valid': uploadSessionIdentifierControl.valid
          }">
        <div class="invalid-feedback" *ngIf="uploadSessionIdentifierControl.touched && uploadSessionIdentifierControl.invalid">
          El identificador es requerido
        </div>
      </div>
      <div class="col-12">
        <button
          type="button"
          class="btn btn-primary float-end"
          [disabled]="uploadSessionIdentifierControl.invalid" (click)="saveUploadSession()">
          Crear
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadSessionImagesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Imágenes subidas de {{ selectedUploadSession.identifier }}
    </h4>
    <button
      type="button"
      class="btn-close pe-4"
      (click)="modal.close()">
    </button>
  </div>
  <div class="modal-body">
    <app-upload-session-image-viewer
      (allImagesLoaded)="allImagesLoaded()"
      [uploadSession]="selectedUploadSession">
    </app-upload-session-image-viewer>
  </div>
</ng-template>
