import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Fancybox } from "@fancyapps/ui";
import { UploadSessionInfo } from "../../../../../../dto/upload-session/upload-session-info.dto";
import { UploadSessionImageInfo } from '../../../../../../dto/upload-session/upload-session-image-info.dto';

@Component({
  selector: 'app-upload-session-image-viewer',
  templateUrl: './upload-session-image-viewer.component.html',
  styleUrls: ['./upload-session-image-viewer.component.css']
})
export class UploadSessionImageViewerComponent implements OnInit, OnDestroy {

  @Input()
  public uploadSession: UploadSessionInfo;

  @Output()
  public allImagesLoaded: EventEmitter<string> = new EventEmitter<string>();

  public imageCounter: number = 0;

  constructor(
    private readonly elRef: ElementRef,
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }

  public loadedImage(uploadSessionImage: UploadSessionImageInfo) {
    uploadSessionImage.loaded = true;
    this.imageCounter++;
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      Toolbar: {
        display: {
          left: ["infobar"],
          middle: [],
          right: ["slideshow", "download", "thumbs", "close"],
        }
      }
    });
    if (this.imageCounter === this.uploadSession.images.length) {
      this.allImagesLoaded.emit();
    }
  }

}
