import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  public spinnerSubject: Subject<any> = new Subject();

  constructor(
    private spinner: NgxSpinnerService
  ) {

  }

  public show(message: string, type?: string) {

    this.spinnerSubject.next({
      message: message,
      type: type || 'ball-scale-multiple'
    });

    this.spinner.show().then(() => {});
  }

  public hide() {
    setTimeout(() => {
      this.spinner.hide().then(() => {})
    }, 200);
  }
}
