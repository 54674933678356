

export const UppyLocaleValues = {
  strings: {
    done: 'Hecho',
    complete: 'Completado',
    uploading: 'Cargando',
    noDuplicates: 'No puedes agregar el mismo archivo %{fileName} dos veces',
    // Cuando `inline: false`, se utiliza como etiqueta leída por lectores de pantalla para el botón que cierra el modal.
    closeModal: 'Cerrar modal',
    // Se utiliza como etiqueta leída por lectores de pantalla para el botón de más (+) que muestra la pantalla "Agregar más archivos".
    addMoreFiles: 'Agregar más archivos',
    addingMoreFiles: 'Agregando más archivos',
    // Se utiliza como encabezado para paneles de importación, por ejemplo, "Importar desde Google Drive".
    importFrom: 'Importar desde %{name}',
    // Cuando `inline: false`, se utiliza como etiqueta leída por lectores de pantalla para el modal del panel de control.
    dashboardWindowTitle: 'Ventana del panel de control de Uppy (Presiona escape para cerrar)',
    // Cuando `inline: true`, se utiliza como etiqueta leída por lectores de pantalla para el área del panel de control.
    dashboardTitle: 'Panel de control de Uppy',
    // Se muestra en el Informer cuando se copia un enlace a un archivo en el portapapeles.
    copyLinkToClipboardSuccess: 'Enlace copiado al portapapeles.',
    // Se utiliza cuando un enlace no se puede copiar automáticamente; el usuario debe seleccionar el texto desde el
    // elemento de entrada a continuación de esta cadena.
    copyLinkToClipboardFallback: 'Copia la URL a continuación',
    // Se utiliza como título al pasar el cursor y etiqueta leída por lectores de pantalla para los botones que copian un enlace de archivo.
    copyLink: 'Copiar enlace',
    back: 'Atrás',
    // Se utiliza como etiqueta leída por lectores de pantalla para los botones que eliminan un archivo.
    removeFile: 'Eliminar archivo',
    // Se utiliza como etiqueta leída por lectores de pantalla para los botones que abren el panel de edición de metadatos de un archivo.
    editFile: 'Editar archivo',
    // Se muestra en el encabezado del editor de metadatos. Se muestra como "Editando image.png".
    editing: 'Editando %{file}',
    // Se utiliza como etiqueta leída por lectores de pantalla para el botón que guarda las ediciones de metadatos y vuelve a la vista de lista de archivos.
    finishEditingFile: 'Finalizar edición del archivo',
    saveChanges: 'Guardar cambios',
    // Se utiliza como etiqueta para el botón de la pestaña que abre el diálogo de selección de archivos del sistema.
    myDevice: 'Mi dispositivo',
    dropHint: 'Suelta tus archivos aquí',
    // Se utiliza como texto al pasar el cursor y etiqueta leída por lectores de pantalla para los indicadores de progreso de archivo cuando
    // se han cargado completamente.
    uploadComplete: 'Carga completa',
    uploadPaused: 'Carga pausada',
    // Se utiliza como texto al pasar el cursor y etiqueta leída por lectores de pantalla para los botones para reanudar cargas pausadas.
    resumeUpload: 'Reanudar carga',
    // Se utiliza como texto al pasar el cursor y etiqueta leída por lectores de pantalla para los botones para pausar cargas.
    pauseUpload: 'Pausar carga',
    // Se utiliza como texto al pasar el cursor y etiqueta leída por lectores de pantalla para los botones para reintentar cargas fallidas.
    retryUpload: 'Reintentar carga',
    // Se utiliza como texto al pasar el cursor y etiqueta leída por lectores de pantalla para los botones para cancelar cargas.
    cancelUpload: 'Cancelar carga',
    // Se utiliza en un título para indicar cuántos archivos están actualmente seleccionados.
    xFilesSelected: {
      0: '%{smart_count} archivo seleccionado',
      1: '%{smart_count} archivos seleccionados',
    },
    uploadingXFiles: {
      0: 'Cargando %{smart_count} archivo',
      1: 'Cargando %{smart_count} archivos',
    },
    processingXFiles: {
      0: 'Procesando %{smart_count} archivo',
      1: 'Procesando %{smart_count} archivos',
    },
    uploadXNewFiles: {
      0: 'Cargar %{smart_count} archivo nuevo',
      1: 'Cargar %{smart_count} archivos nuevos',
    },
    // El enlace "Potenciado por Uppy" en la parte inferior del Panel de control.
    poweredBy: '',
    addMore: 'Agregar más',
    uploadXFiles: {
      0: 'Guardar %{smart_count} archivo',
      1: 'Guardar %{smart_count} archivos',
    },
    editFileWithFilename: 'Editar archivo %{file}',
    save: 'Guardar',
    cancel: 'Cancelar',
    dropPasteFiles: 'Suelta los archivos aquí o %{browseFiles}',
    dropPasteFolders: 'Suelta los archivos aquí o %{browseFolders}',
    dropPasteBoth: 'Suelta los archivos aquí, %{browseFiles} o %{browseFolders}',
    dropPasteImportFiles: 'Suelta los archivos aquí, %{browseFiles} o importa desde:',
    dropPasteImportFolders: 'Suelta los archivos aquí, %{browseFolders} o importa desde:',
    dropPasteImportBoth:
      'Suelta los archivos aquí, %{browseFiles}, %{browseFolders} o importa desde:',
    importFiles: 'Importar archivos desde:',
    browseFiles: 'navegar archivos',
    browseFolders: 'navegar carpetas',
    recoveredXFiles: {
      0: 'No pudimos recuperar completamente 1 archivo. Por favor, selecciónalo nuevamente y reanuda la carga.',
      1: 'No pudimos recuperar completamente %{smart_count} archivos. Por favor, selecciónalos nuevamente y reanuda la carga.',
    },
    recoveredAllFiles: 'Restauramos todos los archivos. Ahora puedes reanudar la carga.',
    sessionRestored: 'Sesión restaurada',
    reSelect: 'Re-seleccionar',
    missingRequiredMetaFields: {
      0: 'Falta campo de metadatos obligatorio: %{fields}.',
      1: 'Faltan campos de metadatos obligatorios: %{fields}.',
    },
  },
}
