import { Component, Input } from '@angular/core';
import { ProductOrderInfo } from '../../../../dto/order/product-order-info.dto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer-form-data',
  templateUrl: './customer-form-data.component.html',
  styleUrls: ['./customer-form-data.component.css']
})
export class CustomerFormDataComponent {

  @Input()
  public productOrder: ProductOrderInfo;

  constructor(
    private readonly toastrService: ToastrService,
  ) {

  }

  public copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value).then(() => {
      this.toastrService.success('Copiado al portapapeles');
    }).catch(err => {
      console.error('Error al copiar al portapapeles: ', err);
    });
  }

}
