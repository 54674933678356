import { Hyperlink } from '../common/hyperlink.dto';

export class FooterHyperlinks {

  title: string;
  hyperlinks: Hyperlink[];

  constructor(title: string, hyperlinks: Hyperlink[]) {
    this.title = title;
    this.hyperlinks = hyperlinks;
  }


}
