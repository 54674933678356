<div class="detail-carousel img-rounded img-shadow"
  [class.transparent]="transparent" [class.not-transparent]="!transparent">
  <div
    class="swiper img-rounded swiper-container detail-slider photoswipe-gallery"
    id="detailSlider">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let image of product?.images">
        <div
          [attr.data-image]="image.src">
          <img
            (load)="onImageLoad()"
            class="img-fluid"
            [src]="image.src"
            alt="cuadro" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="swiper-thumbs" data-swiper="#detailSlider">
  <button class="swiper-thumb-item detail-thumb-item mb-3 active"
          *ngFor="let image of product?.images; let isFirst = first" [ngClass]="{'active': isFirst}">
    <img
      class="img-fluid img-rounded"
      [src]="image.src"
      alt="Push-up Jeans 0" />
  </button>
</div>
