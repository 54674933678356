<footer class="bg-lienzarte">
  <div class="pt-4 pt-sm-4 pt-lg-6 pb-4 px-3 text-muted">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 pe-lg-5 pe-xl-6 mb-2 mb-sm-2 mb-lg-5 mb-lg-0">
          <h4 class="text-light letter-spacing-1 mb-4">
            ¡Contáctanos!
          </h4>
          <p class="text-light mb-3">
            Envíanos un <span class="text-success">WhatsApp</span> y te atenderamos de manera inmediata y personalizada!
          </p>
          <form action="#" id="newsletter-form">
            <div class="input-group input-group-underlined mb-3">
              <input class="form-control form-control-underlined text-light"
                     type="text"
                     placeholder="Envianos tu mensaje"
                     value="Hola, quiero atención personalizada">
              <button class="btn btn-underlined text-light py-0" type="button">
                <i class="fab fa-whatsapp"></i>
              </button>
            </div>
          </form>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-4" *ngFor="let footerHyperlinksColumn of footerHyperlinksColumns; let i = index">
              <a class="d-lg-none block-toggler text-light my-3"
                 data-bs-toggle="collapse"
                 [href]="'#footerMenu' + i">
                {{ footerHyperlinksColumn.title }}
                <span class="block-toggler-icon"></span>
              </a>
              <div class="expand-lg collapse" [id]="'footerMenu' + i">
                <h6 class="text-light letter-spacing-1 mb-4 d-none d-lg-block">
                  {{ footerHyperlinksColumn.title }}
                </h6>
                <ul class="list-unstyled text-sm pt-2 pt-lg-0">
                  <li class="mb-2" *ngFor="let hyperlink of footerHyperlinksColumn.hyperlinks">
                    <a class="text-light text-light-hover link-animated" [href]="hyperlink.url">
                      {{ hyperlink.title }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright section of the footer-->
  <div class="py-4 fw-light text-muted">
    <div class="container">
      <div class="row align-items-center text-sm text-gray-500">
        <div class="col text-center text-lg-start">
          <p class="mb-lg-0">&copy; 2024, Lienzarte. Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
