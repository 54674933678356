import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import {
  design1,
  design10,
  design11,
  design12,
  design13,
  design2,
  design3,
  design4,
  design5,
  design6,
  design7,
  design8,
  design9,
  largePaintingPortraitDesign,
  largePaintingSquareDesign,
  largePaintingLandscapeDesign,
} from './designs.constants';

import { Painting, ProductDesign } from '../../dto/product-design/product-design.dto';
import { firstValueFrom } from 'rxjs';
import { GetSignedUrlRequest } from '../../dto/images/get-signed-url-request.dto';
import { ImageService } from '../../services/image.service';
import { ProductInfo } from '../../dto/shopify/products/product-info.dto';
import { ShoppingCartService } from '../../services/shopping-cart.service';
import { EventEmitterService, NotificationTopic } from '../../services/ui/event-emitter.service';

import html2canvas from 'html2canvas';
import { base64ToBlob } from '../../utils/file.util';
import { FileRequest } from '../../dto/file/file-request.dto';

@Component({
  selector: 'app-product-design',
  templateUrl: './product-design.component.html',
  styleUrls: ['./product-design.component.css']
})
export class ProductDesignComponent implements OnInit {

  public readonly placeHolderImage_2x3: string = 'assets/img/place-holder/lienzarte-logo-2x3.png';
  public readonly placeHolderImage_3x2: string = 'assets/img/place-holder/lienzarte-logo-3x2.png';
  public readonly placeHolderImage_1x1: string = 'assets/img/place-holder/lienzarte-logo-1x1.png';

  @Input()
  public product: ProductInfo;

  public design: ProductDesign;

  public designs: ProductDesign[] = [
    largePaintingLandscapeDesign, largePaintingPortraitDesign, largePaintingSquareDesign,
    design1, design2, design3, design4, design5, design6, design7, design8, design9, design10, design11, design12, design13,
  ];

  public croppingImage: boolean = false;
  public submittingImages: boolean = false;

  public cropImageEvent: EventEmitter<any> = new EventEmitter();
  public croppingImageEvent: EventEmitter<any> = new EventEmitter();
  public croppedImageEvent: EventEmitter<any> = new EventEmitter();

  public selectedPainting: Painting;

  constructor(
    private readonly shoppingCartService: ShoppingCartService,
    private readonly imagesService: ImageService,
    private readonly eventEmitterService: EventEmitterService,
  ) {
  }

  ngOnInit() {
    this.initializeDesign();
    this.initializeCropEvents();
  }

  private initializeCropEvents() {

    // When the image is cropped, the event is emitted
    this.croppedImageEvent.subscribe((event: any) => {

      this.selectedPainting.pictureUrlToCrop = undefined;

      this.selectedPainting.pictureFile = event.file;
      this.selectedPainting.pictureUrl = event.pictureUrl;
    });

    // When the image is being cropped, the event is emitted
    this.croppingImageEvent.subscribe((cropping: boolean) => {
      this.croppingImage = cropping;
    });

  }

  private initializeDesign() {
    this.design = this.designs.find(design => design.code === this.product.design_code);
  }

  public openImagePicker(painting: Painting) {
    this.selectedPainting = painting;
    const input = document.getElementById('imagePicker');
    input.click();
  }

  private clearImagePicker() {
    const input = document.getElementById('imagePicker') as HTMLInputElement;
    input.value = null;
  }

  public onImagePickerSelected(event: Event) {

    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {

      const file = input.files[0];
      this.selectedPainting.pictureFile = file;
      this.selectedPainting.originalPictureFile = file;

      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.selectedPainting.pictureUrlToCrop = event.target.result;
        // this.showImageCropper();
      };

      reader.readAsDataURL(file);

      this.clearImagePicker();
    }
  }

  public cancelCropImage() {
    this.selectedPainting.pictureUrlToCrop = undefined;
    this.selectedPainting.pictureFile = undefined;
    this.selectedPainting.originalPictureFile = undefined;
  }

  public cropImage() {
    // We emit the event to crop the image
    this.cropImageEvent.emit();
  }

  public cancelChoosingImages() {
    // check if we need to clear all the image selections
    this.eventEmitterService.getEventEmitter(NotificationTopic.DesignCancelled).emit();
  }

  public validImages() {
    const { paintings } = this.design;
    const undefinedPainting = paintings.find(p => p.pictureUrl === undefined);
    return undefinedPainting === undefined;
  }

  public async submitImages() {

    this.submittingImages = true;

    const design: ProductDesign = { ...this.design };
    const { paintings } = design;

    if (!this.validImages()) {
      alert('Por favor, suba todas las imágenes');
      return;
    }

    const capturedDesign: string = await this.captureDesign();
    design.capture = await this.uploadCapturedDesign(capturedDesign);

    for (const painting of paintings)
      await this.uploadImage(painting);

    this.shoppingCartService.addProduct(this.product, design);
    this.eventEmitterService.getEventEmitter(NotificationTopic.DesignSubmitted).emit();

    this.submittingImages = false;
  }

  private async captureDesign(): Promise<string> {

    return new Promise((resolve, reject) => {

      const element = document.getElementById('design_container');
      if (!element) {
        reject('Element not found');
        return;
      }

      html2canvas(element)
        .then(canvas => {
          resolve(canvas.toDataURL('image/png'));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public async uploadCapturedDesign(capturedDesign: string): Promise<FileRequest> {

    const cartSession: string = this.shoppingCartService.getCartSession();

    const fileType = 'image/png';
    const fileExtension = 'png';
    const fileName = `design.${fileExtension}`;

    const prefix = `images/uploads/${cartSession}`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, fileName, fileType);
    const getSignedUrlResponse = await firstValueFrom(this.imagesService.getSignedUrl(getSignedUrlRequest));

    const blob = base64ToBlob(capturedDesign);

    await firstValueFrom(this.imagesService.uploadImage(getSignedUrlResponse.signedUrl, blob));

    return new FileRequest(fileName, getSignedUrlResponse.key, getSignedUrlResponse.publicUrl, fileType, blob.size);
  }

  public async uploadImage(painting: Painting) {

    const cartSession: string = this.shoppingCartService.getCartSession();

    const { originalPictureFile } = painting;
    const fileType = originalPictureFile.type;
    const fileExtension = this.getFileExtension(originalPictureFile.name);
    const fileName = `${painting.id}.${fileExtension}`;

    const prefix = `images/uploads/${cartSession}`;
    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, fileName, fileType);
    const getSignedUrlResponse = await firstValueFrom(this.imagesService.getSignedUrl(getSignedUrlRequest));

    const fileKey = getSignedUrlResponse.key;
    const fileUrl = getSignedUrlResponse.publicUrl;
    const fileSize = originalPictureFile.size;

    await firstValueFrom(this.imagesService.uploadImage(getSignedUrlResponse.signedUrl, originalPictureFile));

    painting.fileRequest = new FileRequest(fileName, fileKey, fileUrl, fileType, fileSize);

    delete painting.originalPictureFile;
    delete painting.pictureFile;
    delete painting.pictureUrlToCrop;
    delete painting.pictureUrl;
  }

  private getFileExtension(fileName: string) {
    return fileName.match(/\.([^.]+)$/)[1];
  }

  public getPlaceHolderImage(painting: Painting) {
    switch (painting.ar) {
      case 2 / 3:
        return this.placeHolderImage_2x3;
      case 3 / 2:
        return this.placeHolderImage_3x2;
      case 1:
        return this.placeHolderImage_1x1;
      default:
        return this.placeHolderImage_2x3;
    }
  }

}
