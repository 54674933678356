export const productDescriptions = {
  'sets-de-cuadros':
    `<div>
      <p>
        <strong>Instalación sencilla:</strong>
      </p>
      <p>
        Cada cuadro viene listo para ser pegado en la pared con cinta doble cara,
        facilitando una instalación rápida y sin complicaciones. Alternativamente, incluyen pequeños clavos
        para quienes prefieren colgarlos. También puedes seleccionar una versión en portarretrato,
        que te permite colocar los cuadros donde quieras.
      </p>
      <p>
        <strong>Material de alta durabilidad:</strong>
      </p>
      <p>
        Fabricados en fibra de madera de alta calidad, nuestros cuadros ofrecen una resistencia excepcional.
        Están diseñados para resistir deformaciones y fisuras a lo largo del tiempo, asegurando su durabilidad.
        En <b>Lienzarte</b>, nos comprometemos con el medio ambiente utilizando materiales sustentables en la fabricación de nuestros cuadros.
        La fibra de madera empleada proviene de fuentes gestionadas de manera responsable, garantizando la preservación de los bosques.
        Además, nuestros procesos de producción minimizan los residuos y utilizan tintas ecológicas para reducir el impacto ambiental.
      </p>
      <p>
        <strong>Transforma tu espacio con Lienzarte:</strong>
      </p>
      <p>
        Si buscas una expresión artística personalizada para tu hogar o lugar de trabajo, descubre Lienzarte.
        Nuestros cuadros personalizados están diseñados para capturar tu visión única. Ofrecemos una selección diversa de conjuntos
        y formatos grandes, ideales para adaptarse a cualquier imagen que desees destacar.
        Comienza a personalizar tus propios cuadros ahora y eleva tus fotografías, ya sean capturas de un móvil o tomas deuna cámara
        avanzada, a niveles artísticos excepcionales. Con nuestra avanzada tecnología, garantizamos la más alta calidad en cada pieza que creamos.
      </p>
    </div>`,
  'cuadros-grandes':
    `<div>
      <p>
        <strong>Instalación sencilla:</strong>
      </p>
      <p>
        El cuadro se entrega listo para ser colocado, viene con un ganchito listo para ser colgado con
        clavitos que ya vienen incluídos en tu paquete para su fácil instalación.
      </p>
      <p>
        <strong>Material duradero:</strong>
      </p>
      <p>
        Construido con fibra de madera de primera calidad, este cuadro resiste el paso del tiempo sin deformarse ni agrietarse,
        además de ofrecer una textura y acabado que realzan cualquier imagen impresa en él.
      </p>
      <p>
        <strong>Exclusividad en cada pieza:</strong>
      </p>
      <p>
        En <b>Lienzarte</b>, entendemos que cada espacio es único. Por eso, ofrecemos este cuadro personalizado que refleja tu estilo personal
        y enriquece tu ambiente. Sea que prefieras fotografías vibrantes o sutiles obras de arte, nuestro proceso de impresión de última
        tecnología asegura que cada cuadro captura la esencia de tus momentos favoritos con una calidad insuperable.
      </p>
    </div>`,
  'collages':
    `<div>
      <p>
        <strong>Instalación sencilla:</strong>
      </p>
      <p>
        El cuadro se entrega listo para ser colocado, viene con un ganchito listo para ser colgado con
        clavitos que ya vienen incluídos en tu paquete para su fácil instalación.
      </p>
      <p>
        <strong>Material duradero:</strong>
      </p>
      <p>
        Construido con fibra de madera de primera calidad, este cuadro resiste el paso del tiempo sin deformarse ni agrietarse,
        además de ofrecer una textura y acabado que realzan cualquier imagen impresa en él.
      </p>
      <p>
        <strong>Exclusividad en cada pieza:</strong>
      </p>
      <p>
        En <b>Lienzarte</b>, entendemos que cada espacio es único. Por eso, ofrecemos este cuadro personalizado que refleja tu estilo personal
        y enriquece tu ambiente. Sea que prefieras fotografías vibrantes o sutiles obras de arte, nuestro proceso de impresión de última
        tecnología asegura que cada cuadro captura la esencia de tus momentos favoritos con una calidad insuperable.
      </p>
    </div>`,
  'colecciones': 'Colecciones',
};
