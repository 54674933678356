<div class="container">
  <div class="position-relative">
    <div class="pt-2">
      <div class="row">
        <ng-container *ngFor="let topCollection of topCollections">
          <app-collection-top-card
            [collection]="topCollection"
            class="col-6 col-sm-6 col-md-3 col-lg-3 mb-4 mb-lg-0">
          </app-collection-top-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
