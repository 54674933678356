import { FileRequest } from '../file/file-request.dto';

export class ProductDesign {

  code: string;
  name: string;
  className: string;
  paintings: Painting[];
  capture: FileRequest;

  constructor(code: string, name: string, className: string, paintings: Painting[]) {
    this.code = code;
    this.name = name;
    this.className = className;
    this.paintings = paintings;
  }

}

export class Painting {

  id: string;
  ar: number;
  orientation: string;
  styles: any;

  // Angular

  // Pictures
  originalPictureFile: File;
  pictureFile: File;
  pictureUrl: string;
  pictureUrlToCrop: string;

  // Order
  fileRequest: FileRequest;

  constructor(id: string, ar: number, orientation: string, styles: any) {
    this.id = id;
    this.ar = ar;
    this.orientation = orientation;
    this.styles = styles;
  }

}
