import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';

import { Fancybox } from '@fancyapps/ui';
import { EventEmitterService, NotificationTopic } from '../../../../services/ui/event-emitter.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent implements OnInit, AfterViewInit {

  @Input()
  public imageUrls: string[] = [];

  public imageCounter: number = 0;

  constructor(
    private readonly elRef: ElementRef,
    private readonly eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }

  public goBack() {
    this.eventEmitterService.emit(NotificationTopic.GoBackImageViewer);
  }

  public loadedImage() {
    this.imageCounter++;
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      Toolbar: {
        display: {
          left: ["infobar"],
          middle: [],
          right: ["slideshow", "download", "thumbs", "close"],
        }
      }
    });
  }

}
