
export class CollectionRecord {
  id: number;
  name: string;
  enabled?: boolean;
}

export enum CollectionType {
  SetsOfPaintings = 'sets-de-cuadros',
  LargePaintings = 'cuadros-grandes',
  CollagesPaintings = 'collages',
  CollectionsPaintings = 'colecciones',
}

export const largePaintings: CollectionRecord = {
  id: 303210102958,
  name: 'cuadros-grandes',
  enabled: true,
};

export const setsOfPaintings: CollectionRecord = {
  id: 303835316398,
  name: 'mural-de-cuadros',
  enabled: true,
};

export const collagesPaintings: CollectionRecord = {
  id: 308726562990,
  name: 'collages',
  enabled: false,
};

export const collectionsPaintings: CollectionRecord = {
  id: 308726595758,
  name: 'colecciones',
  enabled: false,
};

export const allCollections: CollectionRecord[] = [
  largePaintings,
  setsOfPaintings,
  collagesPaintings,
  collectionsPaintings,
];

export const getCollectionByName = (name: string): CollectionRecord => {
  return allCollections.find(collection => collection.name === name);
}

export const getCollectionById = (id: number): CollectionRecord => {
  return allCollections.find(collection => collection.id === id);
}

export const getTopCollections = (): CollectionRecord[] => {
  const topCollectionsNames = ['cuadros-grandes', 'mural-de-cuadros', 'collages', 'colecciones'];
  return allCollections.filter(collection => topCollectionsNames.includes(collection.name));
}
