<div class="card card-scale shadow-0 border-0 text-white text-hover-gray-900 overlay-hover-light text-center">
  <img class="card-img img-scale"
       (load)="imgLoaded(collection.image?.src)"
       [src]="collection.image?.src || './assets/img/top/1_test.jpg'"
       alt="Card image">
  <div class="card-img-overlay d-flex align-items-center">
    <div class="w-100 py-3">
      <h2 *ngIf="!collection.enabled">
        {{ collection.title }}
        <span class="badge bg-danger">🔧 En construcción</span>
      </h2>
      <h2 class="display-7 fw-bold mb-0 top-card-text-shadow" *ngIf="collection.enabled">
        {{ collection.title }}
      </h2>
      <a class="stretched-link" [routerLink]="['/collection', collectionRecord?.name]" *ngIf="collection.enabled">
        <span class="sr-only">
          Ver
        </span>
      </a>
    </div>
  </div>
</div>
