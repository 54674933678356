
<div class="container">
  <h3 class="text-center text-primary">
    <i class="fa fa-image-landscape"></i> Sube tus imágenes aquí
  </h3>
  <uppy-dashboard
    *ngIf="uploadSession"
    [uppy]="uppy"
    [props]="uppyDashboardProps">
  </uppy-dashboard>
</div>
