import { Component } from '@angular/core';
import { CustomerGalleryImage } from '../../../../dto/internal/customer-gallery/customer-gallery-image.dto';

@Component({
  selector: 'app-customer-gallery',
  templateUrl: './customer-gallery.component.html',
  styleUrls: ['./customer-gallery.component.css']
})
export class CustomerGalleryComponent {

  public galleryImages: CustomerGalleryImage[] = [
    new CustomerGalleryImage('./assets/img/ugc/5.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/7.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/11.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/4.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/2.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/3.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/6.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/8.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/9.jpg'),
    new CustomerGalleryImage('./assets/img/ugc/10.jpg'),
  ]

}
