<div [class.transparent]="transparent" [class.not-transparent]="!transparent">
  <h1 class="mb-4 mt-1">
    {{ product?.title }}
  </h1>
  <div
    class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between mb-4">
    <ul class="list-inline mb-2 mb-sm-0" *ngIf="product?.variants[0] as variant">
      <li class="list-inline-item h4 fw-light mb-0">
        {{ variant.price | currency: 'MXN' }}
      </li>
      <li class="list-inline-item text-muted fw-light">
        <del>
          {{ variant.compare_at_price | currency: 'MXN' }}
        </del>
      </li>
    </ul>
  </div>
  <form id="buyForm" action="#">
    <div class="row" *ngIf="false">
      <div class="col-sm-6 col-lg-12 detail-option mb-4">
        <h6 class="detail-option-heading">
          Size <span>(required)</span>
        </h6>
        <select
          class="selectpicker"
          name="size"
          data-style="btn-selectpicker">
          <option value="value_0">Small</option>
          <option value="value_1">Medium</option>
          <option value="value_2">Large</option>
        </select>
      </div>
      <div class="col-sm-6 col-lg-12 detail-option mb-5">
        <h6 class="detail-option-heading">
          Type <span>(required)</span>
        </h6>
        <label
          class="btn btn-sm btn-outline-primary detail-option-btn-label"
          for="material_0">
          Hoodie
          <input
            class="input-invisible"
            type="radio"
            name="material"
            value="value_0"
            id="material_0"
            required />
        </label>
        <label
          class="btn btn-sm btn-outline-primary detail-option-btn-label"
          for="material_1">
          College
          <input
            class="input-invisible"
            type="radio"
            name="material"
            value="value_1"
            id="material_1"
            required />
        </label>
      </div>
    </div>
    <div class="input-group w-100 mb-4">
      <div class="flex-grow-1">
        <div class="d-grid h-100">
          <button class="btn btn-dark py-3" type="button"
                  (click)="addProductToCart()">
            <i class="fa fa-shopping-cart me-2"></i>
            Añadir al carrito
          </button>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <a href="#">
          <i class="far fa-heart me-2"></i>
          Agregar a favoritos
        </a>
      </div>
      <div class="col-6 text-end">
        <ul class="list-inline mb-0">
          <li class="list-inline-item me-2">
            <a class="text-dark text-primary-hover" href="#">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a class="text-dark text-primary-hover" href="#">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </form>
  <div [innerHtml]="product?.body_html"></div>
</div>

<ng-template #productDesignModal>
  <div class="modal-body scrollbar p-0">
    <app-product-design [product]="product"></app-product-design>
  </div>
</ng-template>
