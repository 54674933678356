<div class="product product-type-7">
  <div class="product-image border-0">
    <div class="ribbon ribbon-info">
      Nuevo
    </div>
    <div class="product-img-holder position-relative">
      <img class="img-fluid"
           [src]="product.image?.src"
           [alt]="product.image?.alt"
           [attr.data-id]="'product_' + product.id">
    </div>
    <div class="product-hover-overlay">
      <a class="product-hover-overlay-link" [routerLink]="['/product', product.id, product.handle]"></a>
      <a class="product-wishlist btn btn-link btn-lg text-dark p-0" href="#">
        <svg class="svg-icon svg-icon-md svg-icon-heavy">
          <use xlink:href="#heart-1"></use>
        </svg>
      </a>
      <div class="product-hover-overlay-buttons overflow-hidden w-100">
        <a class="btn btn-dark py-3 w-100"
           (click)="addProductToCart()">
          <svg class="svg-icon me-2 svg-icon-sm svg-icon-heavy">
            <use xlink:href="#cart-1"></use>
          </svg>
          Añadir al carrito
        </a>
      </div>
    </div>
  </div>
  <div class="py-3 text-center">
    <h3 class="h5 mb-1">
      <a [ngClass]="{
        'text-dark': !light,
        'text-light': light,
      }" href="#">
        {{ product.title }}
      </a>
    </h3>
    <div class="d-flex align-items-center justify-content-center">
      <p class="product-price text-muted mb-0">
        <ng-container *ngIf="product?.variants[0] as variant">
          <s class="me-2 text-gray-500">
            {{ variant.compare_at_price | currency: 'MXN' }}
          </s>
          <span>
            {{ variant.price | currency: 'MXN' }}
          </span>
        </ng-container>
      </p>
    </div>
  </div>
</div>

<ng-template #productDesignModal>
  <div class="modal-body scrollbar p-0">
    <app-product-design [product]="product"></app-product-design>
  </div>
</ng-template>
