import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrdersService } from '../../../../services/orders.service';
import { ProductOrderInfo } from '../../../../dto/order/product-order-info.dto';
import { ProductOrderStatusType } from '../../../../type/product-order-status.type';
import { EventEmitterService, NotificationTopic } from '../../../../services/ui/event-emitter.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../../services/ui/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  private readonly shopifyBaseUrl: string = 'https://admin.shopify.com/store/a10ea4';

  public ProductOrderStatusType = ProductOrderStatusType;
  public orders: ProductOrderInfo[] = [];
  public selectedOrder: ProductOrderInfo;
  public selectedOrderCustomerFormData: ProductOrderInfo;
  public selectedOrderImageUrls: string[] = [];
  public loadingOrders: boolean = true;

  @ViewChild('customerFormDataModal')
  private customerFormDataModal: TemplateRef<any>;

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly ordersService: OrdersService,
    private readonly eventEmitterService: EventEmitterService,
    private readonly spinnerService: SpinnerService,
    private readonly modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    this.fillOrders();
    this.initializeHideImageViewer();
  }

  private fillOrders() {

    this.ordersService.getOrders().subscribe({
      next: (orders: ProductOrderInfo[]) => {
        this.orders = orders;
        this.loadingOrders = false;
      },
      error: (error) => {
        console.error(error);
        this.loadingOrders = false;
      }
    });

  }

  public reloadOrders() {
    this.orders = [];
    this.fillOrders();
  }

  private initializeHideImageViewer() {

    this.eventEmitterService.getEventEmitter(NotificationTopic.GoBackImageViewer).subscribe({
      next: () => {
        this.selectedOrder = null;
        this.selectedOrderImageUrls = [];
      }
    });
  }

  public viewCustomerForm(order: ProductOrderInfo) {
    if (!confirm('¿Estás seguro de que deseas ver el formulario del cliente?')) return;
    const orderUuid = order.uuid;
    const url: string = this.router.createUrlTree(['/customer/checkout', orderUuid]).toString();
    window.open(url, '_blank');
  }

  public viewCustomerFormData(order: ProductOrderInfo) {

    this.selectedOrderCustomerFormData = order;

    const modalRef = this.modalService.open(this.customerFormDataModal, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'modal-dialog-centered',
    });

    modalRef.dismissed.subscribe(() => {
      this.selectedOrderCustomerFormData = null;
    });

  }

  public copyOrderUrl(order: ProductOrderInfo) {
    const orderUuid = order.uuid;
    const host: string =  location.origin;
    const url: string = `${host}${this.router.createUrlTree(['/customer/checkout', orderUuid]).toString()}`;
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.success('URL copiado al portapapeles', 'Éxito');
    });
  }

  public viewImages(order: ProductOrderInfo) {

    this.selectedOrder = order;
    this.selectedOrderImageUrls = order.images.map(image => image.fileUrl);
  }

  public downloadOrderImages(order: ProductOrderInfo) {

    this.spinnerService.show('Descargando imágenes...');

    this.ordersService.downloadOrderImages(order.uuid).subscribe({
      next: (zipFile: Blob) => {
        const url = window.URL.createObjectURL(zipFile);
        const link = document.createElement('a');
        link.href = url;
        link.download = `order-${order.uuid}.zip`;
        link.click();
        this.spinnerService.hide();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public viewInvoiceUrl(order: ProductOrderInfo) {

    const { shopifyDraftOrder } = order;

    if (shopifyDraftOrder) {
      window.open(order.shopifyDraftOrder.invoice_url, '_blank');
    } else {

    }
  }

  public copyInvoiceUrl(order: ProductOrderInfo) {

    const { shopifyDraftOrder } = order;

    if (shopifyDraftOrder) {
      navigator.clipboard.writeText(order.shopifyDraftOrder.invoice_url).then(() => {
        this.toastr.success('URL copiado al portapapeles', 'Éxito');
      });
    } else {

    }
  }

  public viewOrderUrl(order: ProductOrderInfo) {

    const { shopifyOrder } = order;

    if (shopifyOrder) {
      const url: string = `${this.shopifyBaseUrl}/orders/${order.shopifyOrder.id}`;
      window.open(url, '_blank');
    } else if (order.shopifyDraftOrder) {
      const url: string = `${this.shopifyBaseUrl}/draft_orders/${order.shopifyDraftOrder.id}`;
      window.open(url, '_blank');
    }
  }

  public viewCustomerUrl(order: ProductOrderInfo) {

    const { shopifyOrder } = order;

    if (shopifyOrder) {
      const url: string = `${this.shopifyBaseUrl}/customers/${order.shopifyOrder.customer.id}`;
      window.open(url, '_blank');
    } else {

    }
  }

  public completeDraftOrder(productOrderInfo: ProductOrderInfo) {
    this.spinnerService.show('Completando orden de borrador...');
    this.ordersService.completeDraftOrder(productOrderInfo).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.toastr.success('Orden transformado con éxito', 'Éxito');
        this.fillOrders();
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error(error);
        this.toastr.error('Error al transformar la orden', 'Error');
      }
    });
  }

}
