import { UploadSessionImageRequest } from './upload-session-image-request.dto';

export class SaveUploadSessionRequest {

  uuid: string;
  identifier: string;
  images: UploadSessionImageRequest[] = [];

  constructor(identifier: string) {
    this.identifier = identifier;
  }

}
