import { Component, OnInit } from '@angular/core';

import { facebookUrl, instagramUrl, whatsAppUrl } from '../../../../constants/social-networks.constants';
import { EventEmitterService } from '../../../../services/ui/event-emitter.service';

declare var $: any;

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.css']
})
export class CustomerHeaderComponent implements OnInit {

  public facebookUrl: string = facebookUrl;
  public whatsAppUrl: string = whatsAppUrl;
  public instagramUrl: string = instagramUrl;

  constructor(
    private eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit(): void {
  }

}
