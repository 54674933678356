import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UploadSessionInfo } from "../../../../dto/upload-session/upload-session-info.dto";
import { UploadSessionService } from "../../../../services/upload-session.service";
import { UploadSessionStatusType } from "../../../../type/upload-session-status.type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, Validators } from "@angular/forms";
import { SaveUploadSessionRequest } from "../../../../dto/upload-session/save-upload-session-request.dto";
import { SpinnerService } from "../../../../services/ui/spinner.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { UploadSessionImageInfo } from "../../../../dto/upload-session/upload-session-image-info.dto";
import { Fancybox } from "@fancyapps/ui";

@Component({
  selector: 'app-upload-session-manager',
  templateUrl: './upload-session-manager.component.html',
  styleUrls: ['./upload-session-manager.component.css']
})
export class UploadSessionManagerComponent implements OnInit, OnDestroy {

  public UploadSessionStatusType = UploadSessionStatusType;
  public uploadSessions: UploadSessionInfo[] = [];
  public selectedUploadSession: UploadSessionInfo;
  public loadingUploadSessions: boolean = true;

  public uploadSessionIdentifierControl: FormControl = new FormControl('', [
    Validators.required, Validators.minLength(6), Validators.maxLength(20),
  ]);

  // Modals
  @ViewChild('newUploadSessionModal')
  private newUploadSessionModal: TemplateRef<any>;

  @ViewChild('uploadSessionImagesModal')
  private uploadSessionImagesModal: TemplateRef<any>;

  constructor(
    private readonly elRef: ElementRef,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly toastr: ToastrService,
    private readonly uploadSessionService: UploadSessionService,
    private readonly spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    this.fillUploadSessions();
  }

  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }

  private fillUploadSessions() {

    this.loadingUploadSessions = true

    this.spinnerService.show('Cargando sesiones');

    this.uploadSessionService.getUploadSessions().subscribe({
      next: (uploadSessions: UploadSessionInfo[]) => {
        this.uploadSessions = uploadSessions;
        this.loadingUploadSessions = false;
        this.spinnerService.hide();
      },
      error: (error) => {
        console.error(error);
        this.loadingUploadSessions = false;
        this.spinnerService.hide();
      }
    });
  }

  public saveUploadSession() {

    if (this.uploadSessionIdentifierControl.invalid) return;

    const identifier = this.uploadSessionIdentifierControl.value;
    const saveUploadSessionRequest = new SaveUploadSessionRequest(identifier);

    this.spinnerService.show('Creando sesión');

    this.uploadSessionService.saveUploadSession(saveUploadSessionRequest).subscribe({
      next: () => {
        this.fillUploadSessions();
        this.spinnerService.hide();
        this.modalService.dismissAll();
        this.toastr.success('Sesión creada correctamente');
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error(error);
      }
    });

  }

  public allImagesLoaded() {
    this.spinnerService.hide();
  }

  public viewUploadSession(uploadSession: UploadSessionInfo) {
    this.selectedUploadSession = uploadSession;
    this.spinnerService.show('Cargando imágenes');
    this.uploadSessionService.getUploadSessionImages(this.selectedUploadSession.id).subscribe({
      next: (images: UploadSessionImageInfo[]) => {
        this.selectedUploadSession.images = images;
        this.showUploadSessionImagesModal();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }

  private getUploadSessionUrl(uploadSession: UploadSessionInfo) {
    const uploadSessionUuid = uploadSession.uuid;
    const host: string =  location.origin;
    return `${host}${this.router.createUrlTree(['/customer/upload-session', uploadSessionUuid]).toString()}`;
  }

  public goToUploadSession(uploadSession: UploadSessionInfo) {
    window.open(this.getUploadSessionUrl(uploadSession), '_blank');
  }

  public copyUploadSessionUrl(uploadSession: UploadSessionInfo) {
    const url: string = this.getUploadSessionUrl(uploadSession);
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.success('URL copiado al portapapeles', 'Éxito');
    });
  }

  public deleteUploadSession(uploadSession: UploadSessionInfo) {

    if (!confirm('¿Está seguro de eliminar la sesión?')) return;

    this.spinnerService.show('Eliminando sesión');

    this.uploadSessionService.deleteUploadSession(uploadSession.id).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.fillUploadSessions();
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error(error);
      }
    });
  }

  public reloadUploadSessions() {
    this.uploadSessions = [];
    this.fillUploadSessions();
  }

  public showNewUploadSessionModal() {

    const modalRef = this.modalService.open(this.newUploadSessionModal, {
      centered: true,
      modalDialogClass: 'modal-dialog-centered',
      backdrop: 'static',
    });

    modalRef.hidden.subscribe(() => {
      this.uploadSessionIdentifierControl.reset();
    });
  }

  public showUploadSessionImagesModal() {

    this.modalService.open(this.uploadSessionImagesModal, {
      centered: true,
      size: 'xl',
      modalDialogClass: 'modal-dialog-centered',
      backdrop: 'static',
    });

  }

}
