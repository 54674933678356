<div class="row">
  <div class="col-3 mb-3 d-flex justify-content-center" *ngFor="let uploadSessionImage of uploadSession.images">
    <a [href]="uploadSessionImage.fileUrl" data-fancybox="gallery"
       [class.transparent]="!uploadSessionImage.loaded"
       [class.not-transparent]="uploadSessionImage.loaded">
      <img
        (load)="loadedImage(uploadSessionImage)"
        class="img-fluid image-viewer-item"
        [src]="uploadSessionImage.fileUrl"
        alt="cuadro" />
    </a>
  </div>
</div>
