<section>
  <div class="container" *ngIf="!finalized">
    <div class="row mt-4 mb-5">
      <div class="col-xl-6 offset-xl-1 mb-3">
        <h3 class="text-secondary">
          Formulario de envío
        </h3>
        <form [formGroup]="customerForm" (ngSubmit)="saveForm()" class="row g-2 needs-validation">
          <div class="col-sm-12 col-md-6 mb-2">
            <input
              type="text"
              formControlName="name"
              class="form-control"
              id="name"
              placeholder="Nombre completo *"
              [ngClass]="{
                'is-invalid': customerForm.get('name').touched && customerForm.get('name').invalid,
                'is-valid': customerForm.get('name').valid
              }">
            <div class="invalid-feedback" *ngIf="customerForm.get('name').touched && customerForm.get('name').invalid">
              El nombre es requerido.
            </div>
          </div>
          <div class="col-sm-12 col-md-6 mb-2">
            <input
              type="text"
              formControlName="phoneNumber"
              class="form-control"
              id="phoneNumber"
              placeholder="Número de teléfono *"
              [ngClass]="{
                'is-invalid': customerForm.get('phoneNumber').touched && customerForm.get('phoneNumber').invalid,
                'is-valid': customerForm.get('phoneNumber').valid
              }">
            <div class="invalid-feedback" *ngIf="customerForm.get('phoneNumber').touched && customerForm.get('phoneNumber').invalid">
              Número de teléfono es requerido y debe ser válido.
            </div>
          </div>
          <div class="col-sm-12 col-md-7 mb-2">
            <input
              type="text"
              formControlName="address"
              class="form-control"
              id="address"
              placeholder="Calle y número *"
              required
              [ngClass]="{
                'is-invalid': customerForm.get('address').touched && customerForm.get('address').invalid,
                'is-valid': customerForm.get('address').valid
              }">
            <div class="invalid-feedback" *ngIf="customerForm.get('address').touched && customerForm.get('address').invalid">
              La dirección es requerida.
            </div>
          </div>
          <div class="col-sm-12 col-md-5 mb-2">
            <input
              type="text"
              formControlName="neighborhood"
              class="form-control"
              id="neighborhood"
              placeholder="Colonia *"
              required
              [ngClass]="{
                'is-invalid': customerForm.get('neighborhood').touched && customerForm.get('neighborhood').invalid,
                'is-valid': customerForm.get('neighborhood').valid
              }">
            <div class="invalid-feedback" *ngIf="customerForm.get('neighborhood').touched && customerForm.get('neighborhood').invalid">
              La colonia es requerida.
            </div>
          </div>
          <div class="col-12 mb-2">
            <input
              type="text"
              formControlName="addressReference"
              class="form-control"
              id="addressReference"
              placeholder="Referencias de dirección *"
              [ngClass]="{
                'is-invalid': customerForm.get('addressReference').touched && customerForm.get('addressReference').invalid,
                'is-valid': customerForm.get('addressReference').valid
              }">
            <div class="invalid-feedback" *ngIf="customerForm.get('addressReference').touched && customerForm.get('addressReference').invalid">
              Las referencias son requeridas.
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <input
              type="text"
              formControlName="zip"
              class="form-control"
              id="zip"
              placeholder="Código postal *"
              required
              [ngClass]="{
                'is-invalid': customerForm.get('zip').touched && customerForm.get('zip').invalid,
                'is-valid': customerForm.get('zip').valid
              }">
            <div *ngIf="customerForm.get('zip').touched && customerForm.get('zip').invalid" class="invalid-feedback">
              Código postal es requerido.
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <input
              type="text"
              formControlName="city"
              class="form-control"
              id="city"
              placeholder="Ciudad *"
              required
              [ngClass]="{
                'is-invalid': customerForm.get('city').touched && customerForm.get('city').invalid,
                'is-valid': customerForm.get('city').valid
              }">
            <div *ngIf="customerForm.get('city').touched && customerForm.get('city').invalid" class="invalid-feedback">
              Ciudad es requerida.
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <select
              formControlName="state"
              class="form-select"
              id="state"
              required
              [ngClass]="{
                'is-invalid': customerForm.get('state').touched && customerForm.get('state').invalid,
                'is-valid': customerForm.get('state').valid
              }">
              <option selected disabled value="">Estado *</option>
              <option [value]="state" *ngFor="let state of states">
                {{ state }}
              </option>
            </select>
            <div *ngIf="customerForm.get('state').touched && customerForm.get('state').invalid" class="invalid-feedback">
              Estado es requerido.
            </div>
          </div>
          <div class="col-12">
            <div class="d-grid gap-2">
              <button class="btn btn-primary" type="submit" [disabled]="customerForm.invalid">
                Finalizar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-xl-4" *ngIf="productOrder">
        <div class="card mt-4">
          <div class="card-header">
            <h6 class="mb-0">
              Detalles de su orden
            </h6>
          </div>
          <div class="card-body pt-3">
            <p class="text-muted text-sm mb-0">
              Su orden será enviada a la dirección que usted indique y contendrá los siguientes detalles
            </p>
            <table class="table card-text">
              <tr *ngFor="let lineItem of productOrder.shopifyDraftOrder?.line_items">
                <th class="py-3">
                  {{ lineItem.name }}
                </th>
                <td class="py-3 text-end text-secondary">
                  {{ lineItem.quantity }} X {{ lineItem.price | currency }}
                </td>
              </tr>
              <tr>
                <th class="py-3">
                  Envío
                </th>
                <td class="py-3 text-end text-muted">
                  Gratis
                </td>
              </tr>
              <tr>
                <th class="pt-4 border-0">
                  Total
                </th>
                <td class="pt-4 text-primary text-end h3 fw-normal border-0">
                  {{ productOrder.shopifyDraftOrder?.total_price | currency }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="finalized">
    <div class="row py-2">
      <div class="col-xl-10 col-sm-12 offset-xl-1 offset-xl-1 p-3 bg-white">
        <section class="hero">
          <div class="hero-content">
            <div>
              <div role="alert" class="d-flex align-items-center alert alert-success">
                <svg class="svg-icon w-3rem h-3rem svg-icon-light flex-shrink-0 me-3">
                  <use xlink:href="#checked-circle-1"></use>
                </svg>
                Tus datos de envío han sido completados. Te informaremos por medio de WhatsApp el estatus de tu orden.
              </div>
            </div>
          </div>
        </section>
        <section class="pb-2">
          <p class="lead">Gracias, {{ customerForm.get('name').value }}. Tu orden ha sido <b>completada</b>.</p>
          <p class="lead mb-5">
            Podrás dar seguimiento a tu orden en cualquier momento por medio de <b>WhatsApp</b>. <br>
            Si tienes alguna duda o comentario, por favor contáctanos por ese medio.
          </p>
          <p class="mb-4">
            <a class="btn btn-outline-dark" href="customer-order.html">
              Ver detalles de la orden
            </a>
          </p>
          <div class="p-5 bg-gray-100">
              <div class="row">
                <div class="col-6 col-lg-3 mb-5 mb-lg-0">
                  <div class="text-sm text-uppercase text-muted mb-3">
                    # Orden
                  </div>
                  <span class="h5">
                    {{ productOrder.orderNumber }}
                  </span>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="text-sm text-uppercase text-muted mb-3">
                    Total
                  </div>
                  <span class="h5">
                    {{ productOrder.shopifyDraftOrder.total_price }}
                  </span>
                </div>
                <div class="col-6 col-lg-3">
                  <div class="text-sm text-uppercase text-muted mb-3">
                    Shipping
                  </div>
                  <span class="h5">
                    En preparación
                  </span>
                </div>
              </div>
            </div>
        </section>
      </div>
    </div>
  </div>
</section>
