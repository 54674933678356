import { AfterViewInit, Component, Input } from '@angular/core';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';

declare var initializeAll: any;

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.css']
})
export class ProductSliderComponent implements AfterViewInit {

  public transparent: boolean = true;

  @Input()
  public product: ProductInfo;

  ngAfterViewInit() {
    initializeAll();
  }

  public onImageLoad() {
    this.transparent = false;
  }

}
