
export class UploadSessionImageRequest {

  uuid: string;
  deleted: boolean;
  fileName: string;
  fileKey: string;
  fileUrl: string;
  fileContentType: string;
  fileSize: number;

  constructor(fileName: string, fileKey: string, fileUrl: string, fileContentType: string, fileSize: number) {
    this.fileName = fileName;
    this.fileKey = fileKey;
    this.fileUrl = fileUrl;
    this.fileContentType = fileContentType;
    this.fileSize = fileSize;
  }

}
