
<app-collection-top-cards data-aos="fade-right" data-aos-duration="1500">
</app-collection-top-cards>

<app-video-section data-aos="fade-up" data-aos-duration="1500">
</app-video-section>

<app-trends-cards data-aos="fade-right" data-aos-duration="1500">
</app-trends-cards>

<app-home-services data-aos="fade-up" data-aos-duration="1500">
</app-home-services>

<app-customer-gallery data-aos="fade-up" data-aos-duration="1500">
</app-customer-gallery>

<app-whatsapp-bubble></app-whatsapp-bubble>
