import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './layout/landing/components/header/header.component';
import { FooterComponent } from './layout/landing/components/footer/footer.component';
import { ProductCollectionComponent } from './components/product-collection/product-collection.component';
import { CollectionTopCardComponent } from './components/home/components/collection-top-cards/components/collection-top-card/collection-top-card.component';
import { HttpClientModule } from '@angular/common/http';
import { CollectionTopCardsComponent } from './components/home/components/collection-top-cards/collection-top-cards.component';
import { TrendsCardsComponent } from './components/home/components/trends-cards/trends-cards.component';
import { ProductCardComponent } from './components/common/components/product-card/product-card.component';
import { VideoSectionComponent } from './components/home/components/video-section/video-section.component';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShoppingCartSidebarComponent } from './components/shopping-cart/shopping-cart-sidebar/shopping-cart-sidebar.component';
import { RelatedProductsComponent } from './components/product-view/components/related-products/related-products.component';
import { ProductDescriptionComponent } from './components/product-view/components/product-description/product-description.component';
import { ProductSliderComponent } from './components/product-view/components/product-slider/product-slider.component';
import { ProductReviewsComponent } from './components/product-view/components/product-reviews/product-reviews.component';
import { ProductDataComponent } from './components/product-view/components/product-data/product-data.component';
import { HomeServicesComponent } from './components/home/components/home-services/home-services.component';
import { CustomerGalleryComponent } from './components/home/components/customer-gallery/customer-gallery.component';
import { WhatsAppBubbleComponent } from './components/home/components/whatsapp-bubble/whatsapp-bubble.component';
import { ProductDesignComponent } from './components/product-design/product-design.component';
import { TermsAndConditionsComponent } from './components/informative/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/informative/components/privacy-policy/privacy-policy.component';
import { ImageCropperComponent } from './components/common/components/image-cropper/image-cropper.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrdersComponent } from './components/admin/components/orders/orders.component';
import { LayoutComponent } from './layout/landing/layout.component';
import { AdminLayoutComponent } from './layout/admin/admin-layout.component';
import { AdminHeaderComponent } from './layout/admin/components/header/admin-header.component';
import { LightboxModule } from 'ngx-lightbox';
import { ImageViewerComponent } from './components/admin/components/image-viewer/image-viewer.component';
import { ToastrModule } from 'ngx-toastr';
import { CustomerFormComponent } from './components/customer/components/customer-form/customer-form.component';
import { CustomerLayoutComponent } from './layout/customer/customer-layout.component';
import { CustomerHeaderComponent } from './layout/customer/components/header/customer-header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerFormDataComponent } from './components/admin/components/customer-form-data/customer-form-data.component';
import { UploadSessionComponent } from './components/customer/components/upload-session/upload-session.component';
import { UppyAngularDashboardModule } from "@uppy/angular";
import {
  UploadSessionManagerComponent
} from "./components/admin/components/upload-session-manager/upload-session-manager.component";
import {
  UploadSessionImageViewerComponent
} from './components/admin/components/upload-session-manager/components/upload-session-image-viewer/upload-session-image-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminLayoutComponent,
    AdminHeaderComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    CustomerLayoutComponent,
    CustomerHeaderComponent,
    ProductCollectionComponent,
    CollectionTopCardComponent,
    CollectionTopCardsComponent,
    TrendsCardsComponent,
    ProductCardComponent,
    VideoSectionComponent,
    ProductViewComponent,
    ShoppingCartSidebarComponent,
    RelatedProductsComponent,
    ProductDescriptionComponent,
    ProductSliderComponent,
    ProductReviewsComponent,
    ProductDataComponent,
    ProductDesignComponent,
    HomeServicesComponent,
    CustomerGalleryComponent,
    WhatsAppBubbleComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ImageCropperComponent,
    OrdersComponent,
    ImageViewerComponent,
    CustomerFormComponent,
    CustomerFormDataComponent,
    UploadSessionComponent,
    UploadSessionManagerComponent,
    UploadSessionImageViewerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    LightboxModule,
    UppyAngularDashboardModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({
      type: 'ball-scale-multiple'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
