import { Component, OnInit } from '@angular/core';
import { GetCollectionsRequest } from '../../../../dto/shopify/collections/get-collections-request.dto';
import { CollectionInfo } from '../../../../dto/shopify/collections/collection-info.dto';
import { ProductsService } from '../../../../services/products.service';
import { getTopCollections } from '../../../../constants/collections.constants';

@Component({
  selector: 'app-collection-top-cards',
  templateUrl: './collection-top-cards.component.html',
  styleUrls: ['./collection-top-cards.component.css']
})
export class CollectionTopCardsComponent implements OnInit {

  public topCollections: CollectionInfo[] = [];

  constructor(
    private readonly shopifyProductsService: ProductsService,
  ) {

  }

  ngOnInit() {
    this.fillTopCollections();
  }

  private fillTopCollections() {

    const topCollections = getTopCollections();
    const topCollectionsIds: number[] = topCollections.map(c => c.id);

    const getCollectionsRequest = new GetCollectionsRequest(topCollectionsIds);

    this.shopifyProductsService.getCollections(getCollectionsRequest).subscribe({
      next: (collections: CollectionInfo[]) => {
        for (const collection of topCollections) {
          const collectionInfo = collections.find(c => c.id === collection.id);
          collectionInfo.enabled = collection.enabled;
          this.topCollections.push(collectionInfo);
        }
      },
      error: error => {
        console.error('Error:', error);
      },
    });

  }

}
