<div class="row mb-5">
  <div class="col-lg-10 col-xl-9">
    <div class="media review">
      <div class="flex-shrink-0 text-center me-4 me-xl-5">
        <img
          class="review-image"
          src="./assets/lib/img/avatar/person-1.jpg"
          alt="Han Solo" />
        <span class="text-uppercase text-muted">Dec 2018</span>
      </div>
      <div>
        <h5 class="mt-2 mb-1">Han Solo</h5>
        <div class="mb-2">
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
        </div>
        <p class="text-muted">
          One morning, when Gregor Samsa woke from troubled dreams, he
          found himself transformed in his bed into a horrible vermin.
          He lay on his armour-like back, and if he lifted his head a
          little he could see his brown belly, slightly domed and
          divided by arches into stiff sections
        </p>
      </div>
    </div>
    <div class="media review">
      <div class="flex-shrink-0 text-center me-4 me-xl-5">
        <img
          class="review-image"
          src="./assets/lib/img/avatar/person-2.jpg"
          alt="Luke Skywalker" />
        <span class="text-uppercase text-muted">Dec 2018</span>
      </div>
      <div>
        <h5 class="mt-2 mb-1">Luke Skywalker</h5>
        <div class="mb-2">
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-gray-200"></i>
        </div>
        <p class="text-muted">
          The bedding was hardly able to cover it and seemed ready to
          slide off any moment. His many legs, pitifully thin compared
          with the size of the rest of him, waved about helplessly as he
          looked. &quot;What's happened to me?&quot; he thought. It
          wasn't a dream.
        </p>
      </div>
    </div>
    <div class="media review">
      <div class="flex-shrink-0 text-center me-4 me-xl-5">
        <img
          class="review-image"
          src="./assets/lib/img/avatar/person-3.jpg"
          alt="Princess Leia" />
        <span class="text-uppercase text-muted">Dec 2018</span>
      </div>
      <div>
        <h5 class="mt-2 mb-1">Princess Leia</h5>
        <div class="mb-2">
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-gray-200"></i>
          <i class="fa fa-xs fa-star text-gray-200"></i>
        </div>
        <p class="text-muted">
          His room, a proper human room although a little too small, lay
          peacefully between its four familiar walls. A collection of
          textile samples lay spread out on the table.
        </p>
      </div>
    </div>
    <div class="media review">
      <div class="flex-shrink-0 text-center me-4 me-xl-5">
        <img
          class="review-image"
          src="./assets/lib/img/avatar/person-4.jpg"
          alt="Jabba Hut" />
        <span class="text-uppercase text-muted">Dec 2018</span>
      </div>
      <div>
        <h5 class="mt-2 mb-1">Jabba Hut</h5>
        <div class="mb-2">
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
          <i class="fa fa-xs fa-star text-warning"></i>
        </div>
        <p class="text-muted">
          Samsa was a travelling salesman - and above it there hung a
          picture that he had recently cut out of an illustrated
          magazine and housed in a nice, gilded frame.
        </p>
      </div>
    </div>
    <div class="py-5 px-3">
      <h5 class="mb-4">Leave a review</h5>
      <form
        class="mb-4 form"
        id="reviewForm"
        method="post"
        action="contact.php">
        <div class="row">
          <div class="col-sm-6">
            <div class="mb-3">
              <label class="form-label" for="name">Your name *</label>
              <input
                class="form-control"
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name"
                required="required" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mb-3">
              <label class="form-label" for="rating"
              >Your rating *</label
              >
              <select
                class="form-select focus-shadow-0"
                name="rating"
                id="rating">
                <option value="5">
                  &#9733;&#9733;&#9733;&#9733;&#9733; (5/5)
                </option>
                <option value="4">
                  &#9733;&#9733;&#9733;&#9733;&#9734; (4/5)
                </option>
                <option value="3">
                  &#9733;&#9733;&#9733;&#9734;&#9734; (3/5)
                </option>
                <option value="2">
                  &#9733;&#9733;&#9734;&#9734;&#9734; (2/5)
                </option>
                <option value="1">
                  &#9733;&#9734;&#9734;&#9734;&#9734; (1/5)
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label" for="emailReview"
          >Your email *</label
          >
          <input
            class="form-control"
            type="email"
            name="email"
            id="emailReview"
            placeholder="Enter your  email"
            required="required" />
        </div>
        <div class="mb-3">
          <label class="form-label" for="reviewReview"
          >Review text *</label
          >
          <textarea
            class="form-control"
            rows="4"
            name="review"
            id="reviewReview"
            placeholder="Enter your review"
            required="required"></textarea>
        </div>
        <button class="btn btn-outline-dark" type="submit">
          Post review
        </button>
      </form>
      <p class="text-muted text-sm">
        <span class="badge badge-info-light">Note</span> This form shows
        usage of the classic Bootstrap form controls, not their
        underlined variants. You can choose whichever variant you want.
      </p>
    </div>
  </div>
</div>
