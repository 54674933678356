import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductOrderInfo } from '../../../../dto/order/product-order-info.dto';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../../services/orders.service';
import { CookieService } from '../../../../services/cookie.service';
import { FillCustomerFormRequest } from '../../../../dto/order/form/fill-customer-form-request.dto';
import { states } from '../../../../constants/customer-form.constants';
import { SpinnerService } from '../../../../services/ui/spinner.service';
import { MetaConversionsService } from '../../../../services/meta-conversions.service';
import { RegisterMetaPixelEventRequest } from '../../../../dto/meta/register-meta-pixel-event-request.dto';
import { MetaPixelStandardEvent } from '../../../../type/meta/meta-pixel-standard-event.type';
import { UserDataConversionRequest } from '../../../../dto/meta/user-data-conversion-request.dto';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent {

  public customerForm: FormGroup;
  public productOrder: ProductOrderInfo;
  public states: string[] = states;
  public finalized: boolean = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cookieService: CookieService,
    private readonly orderService: OrdersService,
    private readonly spinnerService: SpinnerService,
    private readonly metaConversionService: MetaConversionsService,
  ) {

  }

  ngOnInit() {
    this.initializeCustomerForm();
    setTimeout(() => this.fillOrder(), 1);
  }

  private initializeCustomerForm() {

    this.customerForm = new FormGroup({
      name: new FormControl('', Validators.required),
      neighborhood: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
      address: new FormControl('', Validators.required),
      addressReference: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      zip: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required)
    });

  }

  private fillOrder() {

    this.spinnerService.show('Cargando...');

    const orderUuid: string = this.route.snapshot.paramMap.get('orderUuid');

    this.orderService.getOrderByUuid(orderUuid).subscribe({
      next: (productOrder: ProductOrderInfo) => {
        this.productOrder = productOrder;
        this.trackInitialMetaEvents();
        this.fillCustomerForm(productOrder);
        this.spinnerService.hide();
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public fillCustomerForm(productOrder: ProductOrderInfo) {

    this.customerForm.patchValue({
      name: productOrder.customerName,
      neighborhood: productOrder.neighborhood,
      phoneNumber: productOrder.phoneNumber,
      address: productOrder.address,
      addressReference: productOrder.addressReference,
      city: productOrder.city,
      zip: productOrder.zip,
      state: productOrder.state ?? ''
    });

  }

  public saveForm() {

    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      return;
    }

    const {
      name, neighborhood, email, phoneNumber,
      address, addressReference, city, zip, state
    } = this.customerForm.value;

    const fillCustomerFormRequest = new FillCustomerFormRequest();
    fillCustomerFormRequest.orderUuid = this.productOrder.uuid;
    fillCustomerFormRequest.customerName = name;
    fillCustomerFormRequest.neighborhood = neighborhood;
    fillCustomerFormRequest.email = email;
    fillCustomerFormRequest.phoneNumber = phoneNumber;
    fillCustomerFormRequest.address = address;
    fillCustomerFormRequest.addressReference = addressReference;
    fillCustomerFormRequest.city = city;
    fillCustomerFormRequest.zip = zip;
    fillCustomerFormRequest.state = state;

    this.spinnerService.show('Guardando...');

    this.orderService.fillCustomerForm(fillCustomerFormRequest).subscribe({
      next: (productOrder: ProductOrderInfo) => {
        this.productOrder = productOrder;
        this.trackFinalMetaEvents().then(() => {

          setTimeout(() => {
            this.spinnerService.hide();
            this.finalized = true;
          }, 1000);

        });
      },
      error: (error) => {
        console.error(error);
      }
    });

  }

  private async getUserData() {

    const fullName = this.productOrder.customerName.split(' ');
    const firstName: string = fullName[0];
    const lastName = fullName[1].replace(firstName, '');

    const userData: UserDataConversionRequest = new UserDataConversionRequest();

    userData.firstName = fullName[0] || '';
    userData.lastName = lastName || '';
    userData.email = this.productOrder.email || '';
    userData.phoneNumber = `52${this.productOrder.phoneNumber}` || '';
    userData.city = this.productOrder.city || '';
    userData.state = this.productOrder.state || '';
    userData.postalCode = this.productOrder.zip || '';
    userData.country = 'MX';
    userData.clientIpAddress = await this.metaConversionService.getPublicIp();
    userData.clientUserAgent = navigator.userAgent;
    userData.externalId = this.metaConversionService.getCustomerSession();
    userData.fbp = this.cookieService.getCookie('_fbp');
    //userData.ctwaClid = this.order.ctwaClid ? this.order.ctwaClid : null;

    return userData;
  };

  private getCustomData() {
    return { value: this.productOrder.shopifyDraftOrder.total_price, currency: 'MXN' };
  }

  private trackInitialMetaEvents() {
    this.trackInitialPixelEvents();
    this.trackInitialConversionsApiEvents(this.productOrder.uuid);
  }

  private trackInitialPixelEvents() {

    // Init pixel
    this.metaConversionService.initPixel();

    // Track page view
    this.metaConversionService.trackPixelEvent('PageView');

    // Track InitiateCheckout
    this.metaConversionService.trackPixelEvent('InitiateCheckout', this.productOrder.uuid);
  }

  private trackInitialConversionsApiEvents(eventId: string) {
    //this.registerMetaPixelEvent(MetaPixelStandardEvent.InitiateCheckout, eventId);
  }

  private async trackFinalMetaEvents() {
    this.trackFinalPixelEvents();
    await this.trackFinalConversionsApiEvents(this.productOrder.uuid);
  }

  private trackFinalPixelEvents() {
    const eventId: string = this.productOrder.uuid;
    this.metaConversionService.trackPixelEvent('AddPaymentInfo', eventId);
    this.metaConversionService.trackPixelEvent('Purchase', eventId, this.getCustomData());
  }

  private async trackFinalConversionsApiEvents(eventId: string) {

    const userData: UserDataConversionRequest = await this.getUserData();
    const customData: any = this.getCustomData();

    this.registerMetaPixelEvent(MetaPixelStandardEvent.AddPaymentInfo, eventId, userData);
    this.registerMetaPixelEvent(MetaPixelStandardEvent.Purchase, eventId, userData, customData);
  }

  private registerMetaPixelEvent(event: MetaPixelStandardEvent, eventId: string, userData?: UserDataConversionRequest, customData?: any) {

    const registerEventRequest = new RegisterMetaPixelEventRequest();

    registerEventRequest.action_source = 'website';
    registerEventRequest.event_source_url = window.location.href;
    registerEventRequest.eventName = event;
    registerEventRequest.eventId = eventId;

    if (userData) registerEventRequest.userData = userData;
    if (customData) registerEventRequest.customData = customData;

    this.metaConversionService.trackConversionsApiEvent(registerEventRequest).subscribe({
      next: () => {
        console.log('Evento registrado')
      },
      error: (error: any) => {
        console.error('Error registrando el evento:', error);
      }
    });
  }

}
