
export class FillCustomerFormRequest  {

  orderUuid: string;
  customerName: string;
  neighborhood: string;
  email: string;
  phoneNumber: string;
  address: string;
  addressReference: string;
  city: string;
  zip: string;
  state: string;

}