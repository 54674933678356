
<div class="card">
  <div class="card-header bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="card-title">
        Imágenes subidas por el cliente
      </h4>
      <button class="btn btn-primary" (click)="goBack()">
        <i class="fa fa-chevron-left"></i>
        Regresar
      </button>
    </div>
  </div>
  <div class="card-body"
       [class.transparent]="imageCounter !== imageUrls.length"
       [class.not-transparent]="imageCounter === imageUrls.length">
    <div class="row">
      <div class="col-3" *ngFor="let imageUrl of imageUrls">
        <a [href]="imageUrl" data-fancybox="gallery">
          <img style="max-height: 300px;"
            (load)="loadedImage()"
            class="img-fluid"
            [src]="imageUrl"
            alt="cuadro" />
        </a>
      </div>
    </div>
  </div>
</div>
