
<app-header></app-header>

<router-outlet></router-outlet>

<app-footer></app-footer>

<ngx-spinner
  [fullScreen]="true"
  [type]="spinnerType"
  size="medium"
  style="color: white;">
  <p class="loading-spinner">
    {{ spinnerMessage ? spinnerMessage : '' }}
  </p>
</ngx-spinner>
