
<div class="py-4 py-lg-6 bg-gray-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-12 py-4 service-column"
        *ngFor="let service of services">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <img [src]="service.imgSrc" class="service-icon" alt="">
          <div class="service-text">
            <h5 class="mb-1">
              {{ service.description }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
