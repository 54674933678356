
export class GetSignedUrlRequest {

  prefix: string;
  fileName: string;
  contentType: string;

  constructor(prefix: string, fileName: string, contentType: string) {
    this.prefix = prefix;
    this.fileName = fileName;
    this.contentType = contentType;
  }

}
