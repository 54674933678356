import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProductInfo } from '../../dto/shopify/products/product-info.dto';
import { ProductsService } from '../../services/products.service';
import { CollectionType } from '../../constants/collections.constants';
import { productDescriptions } from '../../constants/product-descriptions.constants';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
export class ProductViewComponent implements OnInit, AfterViewInit {

  public product: ProductInfo;
  public productDescription: string;
  public relatedProductCollectionType: CollectionType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly shopifyProductsService: ProductsService,
  ) {
    this.setRandomCollectionType();
  }

  ngOnInit() {
    this.initializeRouteListener();
  }

  ngAfterViewInit() {
    window.scroll(0, 0);
  }

  private initializeRouteListener() {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        this.processRouteParams(params);
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }

  private processRouteParams(params: ParamMap) {

    const productId: string = params.get('product-id');
    if (!productId) return;

    this.fillProduct(Number(productId));
  }

  private fillProductDescription() {
    try {
      this.product.body_html += productDescriptions[this.product.product_type];
    } catch (error) {
      console.error('Error:', error);
    }
  }

  private fillProduct(productId: number) {

    this.shopifyProductsService.getProduct(productId).subscribe({
      next: (product: ProductInfo) => {
        this.product = product;
        this.fillProductDescription();
        window.scroll(0, 0);
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }

  private setRandomCollectionType() {

    const collectionTypes: CollectionType[] = [
      CollectionType.SetsOfPaintings,
      CollectionType.LargePaintings,
      //CollectionType.CollagesPaintings,
      //CollectionType.CollectionsPaintings,
    ];

    // random
    this.relatedProductCollectionType = collectionTypes[Math.floor(Math.random() * collectionTypes.length)];
  }

}
