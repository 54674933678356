import { ProductInfo } from '../shopify/products/product-info.dto';
import { ProductDesign } from '../product-design/product-design.dto';

export class CartProduct {

  public product: ProductInfo;
  public quantity: number;
  public designs: ProductDesign[] = [];

  constructor(product: ProductInfo, quantity: number, design: ProductDesign) {
    this.product = product;
    this.quantity = quantity;
    this.designs.push(design);
  }

}
