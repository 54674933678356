import { Component, Input, OnInit } from '@angular/core';
import { CollectionInfo } from '../../../../../../dto/shopify/collections/collection-info.dto';
import { CollectionRecord, getCollectionById } from '../../../../../../constants/collections.constants';

@Component({
  selector: 'app-collection-top-card',
  templateUrl: './collection-top-card.component.html',
  styleUrls: ['./collection-top-card.component.css']
})
export class CollectionTopCardComponent implements OnInit {

  @Input()
  public collection: CollectionInfo;
  public collectionRecord: CollectionRecord;

  ngOnInit() {
    this.collectionRecord = getCollectionById(this.collection.id);
  }

  public imgLoaded(imgSrc: string) {
    console.log(imgSrc);
  }

}
