import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from '../../services/ui/spinner.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {

  public spinnerMessage: string = '';
  public spinnerType: string = 'square-spin';
  private spinnerSubscription: Subscription;

  constructor(
    private spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    this.initializeSpinnerEvent();
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
  }

  private initializeSpinnerEvent() {
    this.spinnerSubscription = this.spinnerService.spinnerSubject
      .subscribe((spinnerData: any) => {
        this.spinnerMessage = spinnerData.message;
        this.spinnerType = spinnerData.type;
      });
  }

}
