import { Component } from '@angular/core';
import { HomeService } from '../../../../dto/internal/home-services/home-service.dto';

@Component({
  selector: 'app-home-services',
  templateUrl: './home-services.component.html',
  styleUrls: ['./home-services.component.css']
})
export class HomeServicesComponent {

  public services: HomeService[] = [
    {
      description: 'Envíos gratis a todo México',
      imgSrc: './assets/img/home-services/shipping.png'
    },
    {
      description: 'Cambios y devoluciones gratis',
      imgSrc: './assets/img/home-services/return.png'
    },
    {
      description: 'Atención personalizada  ',
      imgSrc: './assets/img/home-services/attention.png'
    },
  ];

}
