
export class CustomerGalleryImage {

  imgSrc: string;

  constructor(imgSrc: string) {
    this.imgSrc = imgSrc;
  }

}
