import { Painting, ProductDesign } from '../../dto/product-design/product-design.dto';

export const largePaintingLandscapeDesign = new ProductDesign(
  'large_painting_landscape',
  'Large Painting Landscape',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'large_painting_landscape_1',
      3 / 2,
      'landscape',
      {
        'grid-column': '1 / 13',
        'grid-row': '1 / 6'
      }
    ),
  ]
);

export const largePaintingPortraitDesign = new ProductDesign(
  'large_painting_portrait',
  'Large Painting Portrait',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'large_painting_portrait_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 10',
        'grid-row': '1 / 7'
      }
    ),
  ]
);

export const largePaintingSquareDesign = new ProductDesign(
  'large_painting_square',
  'Large Painting Square',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'large_painting_square_1',
      1,
      'square',
      {
        'grid-column': '4 / 10',
        'grid-row': '1 / 5'
      }
    ),
  ]
);

export const design1 = new ProductDesign(
  'design_1',
  'Design 1',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design1_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design1_2',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design1_3',
      1,
      'square',
      {
        'grid-column': '4 / 7',
        'grid-row': '4 / 6'
      }
    ),
    new Painting(
      'design1_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '7 / 10',
        'grid-row': '3 / 6'
      }
    )
  ]
);

export const design2 = new ProductDesign(
  'design_2',
  'Design 2',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design2_1',
      1,
      'square',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design2_2',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design2_3',
      1,
      'square',
      {
        'grid-column': '4 / 7',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design2_4',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '3 / 5'
      }
    )
  ]
);

export const design3 = new ProductDesign(
  'design_3',
  'Design 3',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design3_1',
      1,
      'square',
      {
        'grid-column': '1 / 4',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design3_2',
      1,
      'square',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design3_3',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design3_4',
      1,
      'square',
      {
        'grid-column': '10 / 13',
        'grid-row': '1 / 3'
      }
    )
  ]
);

export const design4 = new ProductDesign(
  'design_4',
  'Design 4',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design4_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design4_2',
      2 / 3,
      'portrait',
      {
        'grid-column': '7 / 10',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design4_3',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 7',
        'grid-row': '4 / 7'
      }
    ),
    new Painting(
      'design4_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '7 / 10',
        'grid-row': '4 / 7'
      }
    )
  ]
);

export const design5 = new ProductDesign(
  'design_5',
  'Design 5',
  'wrapper wrapper-horizontal wrapper-12-columns',
  [
    new Painting(
      'design5_1',
      3 / 2,
      'landscape',
      {
        'grid-column': '3 / 7',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design5_2',
      3 / 2,
      'landscape',
      {
        'grid-column': '7 / 11',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design5_3',
      3 / 2,
      'landscape',
      {
        'grid-column': '3 / 7',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design5_4',
      3 / 2,
      'landscape',
      {
        'grid-column': '7 / 11',
        'grid-row': '3 / 5'
      }
    )
  ]
);

export const design6 = new ProductDesign(
  'design_6',
  'Design 6',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design6_1',
      1,
      'square',
      {
        'grid-column': '3 / 6',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design6_2',
      3 / 2,
      'landscape',
      {
        'grid-column': '6 / 10',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design6_3',
      3 / 2,
      'landscape',
      {
        'grid-column': '3 / 7',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design6_4',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '3 / 5'
      }
    )
  ]
);

export const design7 = new ProductDesign(
  'design_7',
  'Design 7',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design7_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design7_2',
      3 / 2,
      'landscape',
      {
        'grid-column': '7 / 11',
        'grid-row': '2 / 4'
      }
    ),
    new Painting(
      'design7_3',
      3 / 2,
      'landscape',
      {
        'grid-column': '3 / 7',
        'grid-row': '4 / 6'
      }
    ),
    new Painting(
      'design7_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '7 / 10',
        'grid-row': '4 / 7'
      }
    )
  ]
);


export const design8 = new ProductDesign(
  'design_8',
  'Design 8',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design8_1',
      1,
      'square',
      {
        'grid-column': '2 / 5',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design8_2',
      2 / 3,
      'portrait',
      {
        'grid-column': '5 / 8',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design8_3',
      1,
      'square',
      {
        'grid-column': '8 / 11',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design8_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '2 / 5',
        'grid-row': '3 / 6'
      }
    ),
    new Painting(
      'design8_5',
      1,
      'square',
      {
        'grid-column': '5 / 8',
        'grid-row': '4 / 6'
      }
    ),
    new Painting(
      'design8_6',
      2 / 3,
      'portrait',
      {
        'grid-column': '8 / 11',
        'grid-row': '3 / 6'
      }
    )
  ]
);

export const design9 = new ProductDesign(
  'design_9',
  'Design 9',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design9_1',
      1,
      'square',
      {
        'grid-column': '3 / 6',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design9_2',
      3 / 2,
      'landscape',
      {
        'grid-column': '6 / 10',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design9_3',
      3 / 2,
      'landscape',
      {
        'grid-column': '3 / 7',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design9_4',
      1,
      'square',
      {
        'grid-column': '7 / 10',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design9_5',
      1,
      'square',
      {
        'grid-column': '3 / 6',
        'grid-row': '5 / 7'
      }
    ),
    new Painting(
      'design9_6',
      3 / 2,
      'landscape',
      {
        'grid-column': '6 / 10',
        'grid-row': '5 / 7'
      }
    ),
  ]
);


export const design10 = new ProductDesign(
  'design_10',
  'Design 10',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design10_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '1 / 4',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design10_2',
      2 / 3,
      'portrait',
      {
        'grid-column': '4 / 7',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design10_3',
      2 / 3,
      'portrait',
      {
        'grid-column': '7 / 10',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design10_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '10 / 13',
        'grid-row': '1 / 4'
      }
    ),
  ]
);


export const design11 = new ProductDesign(
  'design_11',
  'Design 11',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design11_1',
      1,
      'square',
      {
        'grid-column': '2 / 5',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design11_2',
      1,
      'square',
      {
        'grid-column': '5 / 8',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design11_3',
      1,
      'square',
      {
        'grid-column': '8 / 11',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design11_4',
      1,
      'square',
      {
        'grid-column': '2 / 5',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design11_5',
      1,
      'square',
      {
        'grid-column': '5 / 8',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design11_6',
      1,
      'square',
      {
        'grid-column': '8 / 11',
        'grid-row': '3 / 5'
      }
    ),
  ]
);


export const design12 = new ProductDesign(
  'design_12',
  'Design 12',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design12_1',
      3 / 2,
      'landscape',
      {
        'grid-column': '1 / 5',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design12_2',
      3 / 2,
      'landscape',
      {
        'grid-column': '5 / 9',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design12_3',
      3 / 2,
      'landscape',
      {
        'grid-column': '9 / 13',
        'grid-row': '1 / 3'
      }
    ),
    new Painting(
      'design12_4',
      3 / 2,
      'landscape',
      {
        'grid-column': '1 / 5',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design12_5',
      3 / 2,
      'landscape',
      {
        'grid-column': '5 / 9',
        'grid-row': '3 / 5'
      }
    ),
    new Painting(
      'design12_6',
      3 / 2,
      'landscape',
      {
        'grid-column': '9 / 13',
        'grid-row': '3 / 5'
      }
    ),
  ]
);

export const design13 = new ProductDesign(
  'design_13',
  'Design 13',
  'wrapper wrapper-12-columns',
  [
    new Painting(
      'design13_1',
      2 / 3,
      'portrait',
      {
        'grid-column': '2 / 5',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design13_2',
      2 / 3,
      'portrait',
      {
        'grid-column': '5 / 8',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design13_3',
      2 / 3,
      'portrait',
      {
        'grid-column': '8 / 11',
        'grid-row': '1 / 4'
      }
    ),
    new Painting(
      'design13_4',
      2 / 3,
      'portrait',
      {
        'grid-column': '2 / 5',
        'grid-row': '4 / 7'
      }
    ),
    new Painting(
      'design13_5',
      2 / 3,
      'portrait',
      {
        'grid-column': '5 / 8',
        'grid-row': '4 / 7'
      }
    ),
    new Painting(
      'design13_6',
      2 / 3,
      'portrait',
      {
        'grid-column': '8 / 11',
        'grid-row': '4 / 7'
      }
    ),
  ]
);

