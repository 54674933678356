import { Component, OnInit } from '@angular/core';
import Uppy, { UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import AwsS3 from '@uppy/aws-s3';
import { GetSignedUrlRequest } from '../../../../dto/images/get-signed-url-request.dto';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ImageService } from '../../../../services/image.service';
import { UppyLocaleValues } from '../../../../constants/uppy.constants';
import { UploadSessionService } from '../../../../services/upload-session.service';
import { SpinnerService } from '../../../../services/ui/spinner.service';
import { UploadSessionInfo } from '../../../../dto/upload-session/upload-session-info.dto';
import { ActivatedRoute } from '@angular/router';
import { SaveUploadSessionRequest } from '../../../../dto/upload-session/save-upload-session-request.dto';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UploadSessionImageRequest } from '../../../../dto/upload-session/upload-session-image-request.dto';
import { UploadSessionImageInfo } from '../../../../dto/upload-session/upload-session-image-info.dto';

@Component({
  selector: 'app-upload-session',
  templateUrl: './upload-session.component.html',
  styleUrls: ['./upload-session.component.css'],
})
export class UploadSessionComponent implements OnInit {

  public uploadSession: UploadSessionInfo;
  public saveUploadSessionRequest: SaveUploadSessionRequest;

  // Uppy
  public uppy: Uppy = new Uppy();
  public uppyDashboardProps: DashboardOptions;

  constructor(
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly spinnerService: SpinnerService,
    private readonly imageService: ImageService,
    private readonly uploadSessionService: UploadSessionService,
  ) {

  }

  ngOnInit() {
    setTimeout(() => this.fillUploadSession(), 1);
  }

  private initializeSaveUploadSessionRequest() {

    this.saveUploadSessionRequest = new SaveUploadSessionRequest(this.uploadSession.identifier);
    this.saveUploadSessionRequest.uuid = this.uploadSession.uuid;

    this.saveUploadSessionRequest.images = this.uploadSession.images.map((image: UploadSessionImageInfo) => {

      const uploadSessionImageRequest = new UploadSessionImageRequest(
        image.fileName, image.fileKey, image.fileUrl, image.fileContentType, image.fileSize,
      );

      uploadSessionImageRequest.uuid = image.uuid;

      return uploadSessionImageRequest;
    });
  }

  private initializeUppy() {

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
    });

    this.uppy.on('files-added', () => {
      if (this.uppy.getFiles().length > 0) {
        setTimeout(() => {
          const element = document.querySelector('.uppy-Dashboard-files');
          if (element) element.classList.add('scrollbar');
        }, 1);
      }
    });

    this.uppy.on('complete', (fileUploadResult: any) => {

      this.uppy.setState({
        files: {},
        currentUploads: {},
        totalProgress: 0,
      });

      this.saveUploadSession(fileUploadResult);
    });

    this.uppy.on('file-added', (file) => {
      if (!file.meta['uuid'] && this.saveUploadSessionRequest.images.find((image) => image.fileName === file.name)) {
        this.uppy.removeFile(file.id);
        this.toastr.error('El archivo ya ha sido subido');
      }
    });

    this.uppy.on('file-removed', (file, reason) => {

      if (reason === 'removed-by-user') {

        if (!confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
          this.uppy.addFile(file);
          return;
        }

        const uuid = file.meta['uuid'];

        const imageToDelete = this.saveUploadSessionRequest.images.find((image) => image.uuid === uuid);
        if (imageToDelete) imageToDelete.deleted = true;
      }
    });

    this.uppyDashboardProps = {
      width: '100%',
      height: 'calc(100vh - 200px)',
      doneButtonHandler: null,
    };

    this.uppy.use(AwsS3, {
      id: 'uppy-aws-s3',
      getUploadParameters: async (uppyFile: UppyFile) => {

        const prefix: string = `${this.uploadSession.identifier}`;
        const getSignedUrlRequest = new GetSignedUrlRequest(prefix, uppyFile.name, uppyFile.type);
        const response = await lastValueFrom(this.imageService.getSignedUrl(getSignedUrlRequest));

        (uppyFile as any).fileKey = response.key;

        return {
          method: 'PUT',
          url: response.signedUrl,
          headers: {
            'Content-Type': uppyFile.type,
          },
        };
      },
    });

    this.uppy.use(Dashboard, this.uppyDashboardProps);
  }

  private fillUploadSession() {

    this.spinnerService.show('Cargando...');

    const uploadSessionUuid = this.route.snapshot.paramMap.get('uploadSessionUuid');

    this.uploadSessionService.getUploadSession(uploadSessionUuid).subscribe({
      next: (uploadSession: UploadSessionInfo) => {
        this.uploadSession = uploadSession;
        this.initializeUppy();
        this.initializeSaveUploadSessionRequest();
        this.fillUploadedImages().then(() => this.spinnerService.hide());
      },
      error: (error: HttpErrorResponse) => {
        this.spinnerService.hide();
        try {
          if (error.error.code === 'UPLOAD_SESSION_NOT_FOUND') {
            this.toastr.error('La sesión no existe');
            this.uppy.close();
          }
        } catch (err) {
          this.toastr.error('Error al cargar la sesión');
        }
      },
    });

  }

  private async fillUploadedImages() {

    const uploadPromises = this.uploadSession.images.map(async (image) => {
      try {
        const blob = await firstValueFrom(this.imageService.getBlobImageFromUrl(image.fileUrl));
        this.uppy.addFile({
          name: image.fileName,
          type: image.fileContentType,
          data: blob,
          size: image.fileSize,
          meta: {
            uuid: image.uuid,
          },
        });
      } catch (error) {
        console.error(error);
      }
    });

    await Promise.all(uploadPromises);
  }

  private saveUploadSession(fileUploadResult: any) {

    for (const uploadedUppyFile of fileUploadResult.successful) {

      const {
        uploadURL: fileUrl,
        name: fileName,
        type: fileContentType,
        size: fileSize,
        fileKey,
      } = uploadedUppyFile as any;

      const uploadSessionImageRequest = new UploadSessionImageRequest(
        fileName, fileKey, fileUrl, fileContentType, fileSize,
      );

      if (uploadedUppyFile.meta?.uuid) {
        uploadSessionImageRequest.uuid = uploadedUppyFile.meta.uuid;
      }

      this.saveUploadSessionRequest.images.push(uploadSessionImageRequest);
    }

    this.spinnerService.show('Creando sesión');

    this.uploadSessionService.saveUploadSession(this.saveUploadSessionRequest).subscribe({
      next: (uploadSession: UploadSessionInfo) => {
        // for (const image of this.saveUploadSessionRequest.images) {
        //   image.uuid = uploadSession.images.find((i) => i.fileKey === image.fileKey).uuid;
        // }
        this.fillUploadSession();
        this.toastr.success('Imagenes guardadas correctamente');
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error(error);
      },
    });

  }

}
