import { Injectable } from '@angular/core';
import { CartProduct } from '../dto/shopping-cart/cart-product.dto';
import { ProductInfo } from '../dto/shopify/products/product-info.dto';
import { EventEmitterService, NotificationTopic } from './ui/event-emitter.service';
import { ProductDesign } from '../dto/product-design/product-design.dto';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  public total: number = 0;
  private cartProducts: CartProduct[] = [];
  private cartSession: string;

  private readonly CART_KEY: string = 'shopping_cart';
  private readonly CART_SESSION_KEY: string = 'shopping_cart_session';
  private readonly MAX_ITEMS_PER_GROUP: number = 100;

  constructor(
    private readonly eventEmitterService: EventEmitterService,
  ) {
    this.loadCart();
    this.loadSessionCart();
  }

  private loadCart(): void {
    const storedCart = localStorage.getItem(this.CART_KEY);
    if (storedCart) {
      this.cartProducts = JSON.parse(storedCart);
    } else {
      this.cartProducts = [];
    }
    this.saveCart();
  }

  private loadSessionCart(): void {
    const storedCartSession = localStorage.getItem(this.CART_SESSION_KEY);
    if (storedCartSession) {
      this.cartSession = storedCartSession;
    } else {
      this.cartSession = this.generateSession();
    }
    this.saveCartSession();
  }

  private generateSession(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  private saveCartSession() {
    localStorage.setItem(this.CART_SESSION_KEY, this.cartSession);
  }

  public getCartSession(): string {
    return this.cartSession;
  }

  private saveCart(): void {
    localStorage.setItem(this.CART_KEY, JSON.stringify(this.cartProducts));
    this.total = this.updateTotal();
    this.eventEmitterService.emit(NotificationTopic.CartUpdated);
  }

  public getShoppingCart(): CartProduct[] {
    return this.cartProducts;
  }

  public getTotalCartProducts(): number {
    let totalCartProducts: number = 0;
    for (const cartProduct of this.cartProducts) {
      totalCartProducts += cartProduct.quantity;
    }
    return totalCartProducts;
  }

  public getTotal() {
    return this.total;
  }

  public updateTotal(): number {
    let total: number = 0;
    for (const cartProduct of this.cartProducts) {
      const variant = cartProduct.product.variants[0];
      const price = variant ? variant.price : 0;
      total += cartProduct.quantity * Number(price);
    }
    return total;
  }

  public addProduct(product: ProductInfo, design: ProductDesign) {

    if (!product || !product.id) {
      throw new Error('Invalid product');
    }

    const productId: number = product.id;

    const cartProduct: CartProduct = this.cartProducts
      .find(cp => cp.product.id === productId);

    if (!cartProduct) {
      this.cartProducts.push(new CartProduct(product, 1, design));
    } else {
      if (cartProduct.quantity < this.MAX_ITEMS_PER_GROUP) {
        cartProduct.quantity++;
        cartProduct.designs.push(design);
      }
    }

    this.saveCart();
    this.eventEmitterService.emit(NotificationTopic.CartProductAdded);
  }

  private findProduct(product: ProductInfo): CartProduct {
    return this.cartProducts.find(cp => cp.product.id === product.id);
  }

  public incrementProduct(product: ProductInfo) {
    const cartProduct: CartProduct = this.findProduct(product);
    if (!cartProduct) return;
    cartProduct.quantity++;
    this.saveCart();
  }

  public decrementProduct(product: ProductInfo) {
    const cartProduct: CartProduct = this.findProduct(product);
    if (!cartProduct) return;
    cartProduct.quantity--;
    this.saveCart();
  }

  public removeProduct(product: ProductInfo) {
    const cartProductIndex: number = this.cartProducts.findIndex(cp => cp.product.id === product.id);
    if (cartProductIndex >= 0)
      this.cartProducts.splice(cartProductIndex, 1);
    this.saveCart();
  }

  public clearCart(): void {
    this.cartProducts = [];
    this.saveCart();
  }

}
