import { UserDataConversionRequest } from "./user-data-conversion-request.dto";
import { MetaPixelStandardEvent } from '../../type/meta/meta-pixel-standard-event.type';

export class RegisterMetaPixelEventRequest {

  // standard event
  eventName: MetaPixelStandardEvent;
  eventId: string;

  // website action source
  action_source: string;
  client_user_agent: string;
  event_source_url: string;

  // user data normally
  userData: UserDataConversionRequest;
  customData: any;

}
