
export const whatsAppPhoneNumber: string = '+523330241128';

export const getWhatsAppUrl = (): string => {
  const initialMessage: string = 'Hola, me gustaría que me dieras más información sobre tus cuadros';
  return `https://wa.me/${whatsAppPhoneNumber}?text=${initialMessage}`;
}

export const facebookUrl: string = 'https://www.facebook.com/profile.php?id=61556701186019';
export const whatsAppUrl: string = getWhatsAppUrl();
export const instagramUrl: string = facebookUrl;
