<header class="header">
  <!-- Top Bar-->
  <nav class="navbar navbar-expand-xl navbar-dark bg-lienzarte px-lg-5 py-3 shadow mb-4">
    <div class="container d-flex">
      <div class="col-sm-12 col-lg-6 offset-lg-1">
        <a class="navbar-brand" href="../../../../../index.html">
          <img class="navbar-brand-img" src="./assets/img/logo/lienzarte-logo-white-letters.png" alt="lienzarte-logo-white">
        </a>
      </div>
    </div>
  </nav>
</header>

<div class="modal fade modal-right" id="sidebarCart" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content sidebar-cart-content">
      <app-shopping-cart-sidebar></app-shopping-cart-sidebar>
    </div>
  </div>
</div>
