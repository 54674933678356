import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService, NotificationTopic } from '../../../../services/ui/event-emitter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-data',
  templateUrl: './product-data.component.html',
  styleUrls: ['./product-data.component.css']
})
export class ProductDataComponent implements OnInit, OnDestroy {

  public isMobile: boolean = false;
  public transparent: boolean = true;

  @Input()
  public product: ProductInfo;

  @ViewChild('productDesignModal')
  private productDesignModal: TemplateRef<any>;

  private readonly componentSubscription: Subscription[] = [];

  constructor(
    private readonly modalService: NgbModal,
    private readonly eventEmitterService: EventEmitterService,
  ) {

  }

  ngOnInit() {
    this.initializeOnDesignEvents();
    this.isMobile = window.innerWidth <= 992;
    setTimeout(() => this.transparent = false, 100);
  }

  ngOnDestroy() {
    for (const subscription of this.componentSubscription) {
      subscription.unsubscribe();
    }
  }

  private initializeOnDesignEvents() {

    const designSubmittedSubscription = this.eventEmitterService.getEventEmitter(NotificationTopic.DesignSubmitted).subscribe(() => {
      this.modalService.dismissAll();
    });

    const designCancelledSubscription = this.eventEmitterService.getEventEmitter(NotificationTopic.DesignCancelled).subscribe(() => {
      this.modalService.dismissAll();
    });

    this.componentSubscription.push(designSubmittedSubscription);
    this.componentSubscription.push(designCancelledSubscription);
  }

  public addProductToCart() {
    this.showProductDesign();
  }

  public showProductDesign() {

    this.modalService.open(this.productDesignModal, {
      centered: true,
      scrollable: true,
      size: 'lg',
      modalDialogClass: 'modal-dialog-centered',
      backdrop: 'static',
    });

  }

}
