
<div class="card">
  <div class="card-header">
    Información de envío - Orden #{{ productOrder.orderNumber }}
  </div>
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Nombre del Cliente:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.customerName }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.customerName"
             (click)="copyToClipboard(productOrder.customerName)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Colonia:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.neighborhood }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.neighborhood"
             (click)="copyToClipboard(productOrder.neighborhood)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Email:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.email }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.email"
             (click)="copyToClipboard(productOrder.email)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Teléfono:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.phoneNumber }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.phoneNumber"
             (click)="copyToClipboard(productOrder.phoneNumber)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Dirección:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.address }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.address"
             (click)="copyToClipboard(productOrder.address)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Referencia de Dirección:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.addressReference }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.addressReference"
             (click)="copyToClipboard(productOrder.addressReference)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Ciudad:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.city }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.city"
             (click)="copyToClipboard(productOrder.city)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Código Postal:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.zip }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.zip"
             (click)="copyToClipboard(productOrder.zip)"></i>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-3"><strong>Estado:</strong></div>
      <div class="col-sm-9">
        <div class="d-flex justify-content-start align-items-center">
          <span>
            {{ productOrder.state }}
          </span>
          <i class="fa-regular fa-copy ms-2 text-primary" role="button"
             *ngIf="productOrder.state"
             (click)="copyToClipboard(productOrder.state)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
