import { Component, Input } from '@angular/core';
import { ProductInfo } from '../../../../dto/shopify/products/product-info.dto';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css']
})
export class ProductDescriptionComponent {

  @Input()
  public product: ProductInfo;

}
